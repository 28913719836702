<template>
  <div class="index">
    <v-header ref="header"></v-header>

    <!-- 轮播图 -->
    <div
      class="banner"
      id="ban"
      style="margin:0 auto"
    >
      <img
        class="img"
        :src="img[0]"
      >
      <ul>
        <li
          v-for="(item, index) in 4"
          :key="index"
          @click="change_img(index)"
          :style="index == ind ? 'background:#ccc' : ''"
        ></li>

      </ul>
      <!-- 安卓下载 -->
      <div
        class="android"
        v-if="android"
        @click="dowload_android"
      >
        <img
          src="../../assets/img/组 1482.png"
          alt=""
        >
        <span>Android 下载</span>
      </div>
      <!-- ios下载 -->
      <div
        class="ios"
        v-if="android"
        @click="dowload_ios"
      >
        <img
          src="../../assets/img/组 1481.png"
          alt=""
        >
        <span>IOS 下载</span>
      </div>

      <!-- 开始使用 -->
      <div
        class="start"
        v-if="start"
        @click="gokongjian"
      >开始使用</div>
      <!-- 了解更多-平台开发 -->
      <div
        class="more"
        v-if="more"
        @click="dataserve"
      >了解更多</div>
      <!-- 了解更多--数据服务 -->
      <div
        class="about"
        v-if="about"
        @click="yewupingtaikaifa"
      >了解更多</div>
    </div>

    <!--  主体内容 -->
    <div
      class="main"
      style="margin:0 auto"
    >
      <div class="main-top">
        <div class="title">产品业务</div>
        <div class="line">
          <div class="line1"></div>
          <span class="dian1"></span>
          <span class="dian2"></span>
          <div class="line2"></div>
        </div>
        <div class="en_title">PRODUCT BUSINESS</div>
        <ul class="ul">
          <li
            @click="dataserve"
            style="margin-left:26px"
          >
            <img
              src="../../assets/img/组 906.png"
              alt=""
            >
            <h4>数据服务</h4>
            <p>提供各类空间栅矢数据的加工、文档数据处理，数据库设计与建设及专题数图件编制等专业化服务</p>
          </li>
          <li @click="yewupingtaikaifa">
            <img
              src="../../assets/img/组 995.png"
              alt=""
            >
            <h4>平台开发服务</h4>
            <p>帮助定制实现空间数据可视化、检索，后台数据管理、统计分析，用户授权管理的专属解决方案</p>
          </li>
          <li @click="yidongapp">
            <img
              src="../../assets/img/组 996.png"
              alt=""
            >
            <h4>第四记移动APP</h4>
            <p>基于地理位置的数据采集产品，帮助更好实现户外所见的便捷记录和高效梳理</p>
          </li>
          <li
            style="margin-right:26px"
            @click="kongjianpingtai"
          >
            <img
              src="../../assets/img/组 997.png"
              alt=""
            >
            <h4>第四记空间平台</h4>
            <p>提供丰富的资源及工具，帮您实现数据的多端同步查看和便捷管理</p>
          </li>
        </ul>
      </div>
      <div class="main-top">
        <div class="title">解决方案</div>
        <div class="line">
          <div class="line1"></div>
          <span class="dian1"></span>
          <span class="dian2"></span>
          <div class="line2"></div>
        </div>
        <div class="en_title">SOLUTION</div>
        <ul
          class="main-middle"
          style="margin-bottom:35px"
        >
          <li
            @click="shujujiagong"
            style="margin-top:80px"
          >
            <img
              src="../../assets/img/组 985.png"
              alt=""
            >
            <h4>
              <span>数据加工</span>
              <div
                class="yuandian"
                style="display:none"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
              <div
                class="yuandian1"
                style="display:none"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
            </h4>
            <a
              href="javascript:;"
              style="display:none"
            >点击查看</a>

          </li>
          <li
            @click="shujuku"
            style="margin-top:80px"
          >
            <img
              src="../../assets/img/组 985.png"
              alt=""
            >
            <h4>
              <span>数据库建设</span>
              <div
                class="yuandian"
                style="display:none;right: 92px;"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
              <div
                class="yuandian1"
                style="display:none;left: 98px;"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
            </h4>
            <a
              href="javascript:;"
              style="display:none"
            >点击查看</a>

          </li>
          <li
            @click="shujuzhitu"
            style="margin-top:80px"
          >
            <img
              src="../../assets/img/组 985.png"
              alt=""
            >
            <h4><span>数据制图</span>
              <div
                class="yuandian"
                style="display:none;right: 100px;"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
              <div
                class="yuandian1"
                style="display:none;left: 106px;"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
            </h4>
            <a
              href="javascript:;"
              style="display:none"
            >点击查看</a>

          </li>
        </ul>
        <ul
          class="main-middle-two"
          style="margin-top:0"
        >
          <li
            style="margin-right:15px"
            @click="pingtaikaifa"
          >
            <img
              src="../../assets/img/组 985.png"
              alt=""
            >
            <h4><span>平台开发</span>
              <div
                class="yuandian"
                style="display:none;right: 100px;"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
              <div
                class="yuandian1"
                style="display:none;left: 106px;"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
            </h4>
            <a
              href="javascript:;"
              style="display:none"
            >点击查看</a>

          </li>
          <li
            style="margin-left:15px"
            @click="shujucaiji"
          >
            <img
              src="../../assets/img/组 985.png"
              alt=""
            >
            <h4><span>户外数据采集</span>
              <div
                class="yuandian"
                style="display:none;right: 82px;"
                id="yuandian"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
              <div
                class="yuandian1"
                style="display:none;left: 91px;"
              >
                <i></i>
                <i class="i"></i>
                <i class="ii"></i>
              </div>
            </h4>
            <a
              href="javascript:;"
              style="display:none"
            >点击查看</a>

          </li>
        </ul>
      </div>
      <div
        class="main-top"
        style="height:auto"
      >
        <!-- <div class="title">合作伙伴</div>
        <div class="line">
          <div class="line1"></div>
          <span class="dian1"></span>
          <span class="dian2"></span>
          <div class="line2"></div>
        </div>
        <div class="en_title">COOPERATIVE PARTNER</div> -->
        <!-- <div
          class="china-map"
          style="height:auto"
        >
          <img
            style="margin-top:80px;margin-bottom: 100px;"
            src="../../assets/img/图片1.jpg"
            alt=""
          >
        </div> -->
      </div>
    </div>
    <!-- 底部 -->
    <v-footer></v-footer>

  </div>
</template>

<script>
import img1 from "../../assets/img/2443.png";
import img2 from "../../assets/img/web.jpg";
import img3 from "../../assets/img/2444.png";
import img4 from "../../assets/img/2450.png";
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import {
  changewidth,
  decide_router,
  getwidth,
} from "../../unitils/decide_router.js";
import axios from "axios";
export default {
  components: {
    vHeader,
    vFooter,
  },
  data() {
    return {
      shu: 0,
      img: [img1, img2, img3, img4],
      ind: 0,
      num: 0,
      start: false,
      about: false,
      more: false,
      android: true,
      screenWidth: "",
      screenHeight: "",
      url: "",
      serve: "",
      inner: null,
      viewwidth: "",
    };
  },
  methods: {
    // ios下载
    dowload_ios() {
      window.open("https://apps.apple.com/cn/app/id1571845119");
    },
    // android下载
    dowload_android() {
      axios({
        url: "https://apiv3.geoquater.com/v3/system/update",
        method: "get",
      }).then((res) => {
        if (res.status == 200) {
          console.log(res);
          window.open(res.data.data.apkUrl);
        }
      });
    },
    // banner 空间平台图片按钮 跳转至空间平台网站
    gokongjian() {
      window.open("http://map.geoquater.com/#/map");
    },
    // 产品业务板块点击跳转
    dataserve() {
      this.$router.push("/dataserve");
    },
    yewupingtaikaifa() {
      this.$router.push("/pingtaikaifa");
    },
    yidongapp() {
      this.$router.push("/yidongapp");
    },
    kongjianpingtai() {
      this.$router.push("/kongjianpingtai");
    },

    // 解决方案板块点击跳转
    shujujiagong() {
      this.$router.push("/datajiagong");
    },
    shujuku() {
      this.$router.push("/shujukujianshe");
    },
    shujuzhitu() {
      this.$router.push("/shujuzhitu");
    },
    pingtaikaifa() {
      this.$router.push("/jiejuepingtaikaifa");
    },
    shujucaiji() {
      this.$router.push("/huwaicaiji");
    },
    // 解决方案板块，鼠标移入移出
    over() {
      let title = document.querySelectorAll(
        ".main .main-top .main-middle li h4"
      );
      let a = document.querySelectorAll(".main .main-top .main-middle li a");
      let title_two = document.querySelectorAll(
        ".main .main-top .main-middle-two li h4"
      );
      let a_two = document.querySelectorAll(
        " .main .main-top .main-middle-two li a"
      );
      let dian = document.querySelectorAll(
        ".main .main-top .main-middle li .yuandian"
      );
      let left_dian = document.querySelectorAll(
        ".main .main-top .main-middle li .yuandian1"
      );
      let dian2 = document.querySelectorAll(
        ".main .main-top .main-middle-two li .yuandian"
      );
      let left_dian2 = document.querySelectorAll(
        ".main .main-top .main-middle-two li .yuandian1"
      );
      for (let i = 0; i < title.length; i++) {
        title[i].onmouseover = () => {
          dian[i].style.display = "block";
          left_dian[i].style.display = "block";
          title[i].style.background = "rgba(0, 105, 230, 0.4)";
          title[i].style.color = "white";
          title[i].style.borderRadius = "15px";
          a[i].style.display = "block";
        };
      }
      for (let n = 0; n < title_two.length; n++) {
        title_two[n].onmouseover = () => {
          title_two[n].style.background = "rgba(0, 105, 230, 0.4)";
          title_two[n].style.borderRadius = "15px";
          title_two[n].style.color = "white";
          a_two[n].style.display = "block";
          dian2[n].style.display = "block";
          left_dian2[n].style.display = "block";
        };
      }
    },
    out() {
      let dian2 = document.querySelectorAll(
        ".main .main-top .main-middle-two li .yuandian"
      );
      let title = document.querySelectorAll(
        ".main .main-top .main-middle li h4"
      );
      let a = document.querySelectorAll(".main .main-top .main-middle li a");
      let title_two = document.querySelectorAll(
        ".main .main-top .main-middle-two li h4"
      );
      let a_two = document.querySelectorAll(
        " .main .main-top .main-middle-two li a"
      );
      let dian = document.querySelectorAll(
        ".main .main-top .main-middle li .yuandian"
      );
      let left_dian = document.querySelectorAll(
        ".main .main-top .main-middle li .yuandian1"
      );
      let left_dian2 = document.querySelectorAll(
        ".main .main-top .main-middle-two li .yuandian1"
      );
      for (let i = 0; i < title.length; i++) {
        title[i].onmouseout = () => {
          title[i].style.background = "";
          title[i].style.color = "black";
          a[i].style.display = "none";
          dian[i].style.display = "none";
          left_dian[i].style.display = "none";
        };
      }
      for (let n = 0; n < title_two.length; n++) {
        title_two[n].onmouseout = () => {
          title_two[n].style.background = "";
          title_two[n].style.color = "black";
          a_two[n].style.display = "none";
          dian2[n].style.display = "none";
          left_dian2[n].style.display = "none";
        };
      }
    },
    // 轮播点击切图
    change_img(ind) {
      // console.log(ind);
      this.num = ind;
      let imgurl = document.querySelector(".banner .img");
      if (ind == 0) {
        imgurl.src = "http://" + this.serve + img1;
        this.url = imgurl.src;
      }
      if (ind == 1) {
        imgurl.src = "http://" + this.serve + img2;
        this.url = imgurl.src;
      }
      if (ind == 2) {
        imgurl.src = "http://" + this.serve + img3;
        this.url = imgurl.src;
      }
      if (ind == 3) {
        imgurl.src = "http://" + this.serve + img4;
        this.url = imgurl.src;
      }
      let li = (li = document.querySelectorAll(".banner ul li"));
      for (let i = 0; i < li.length; i++) {
        if (i == ind) {
          li[i].style.background = "#ccc";
        } else {
          li[i].style.background = "";
        }
      }
    },
    // 轮播图自动切图
    autoplay() {
      var imgurl = document.querySelector(".banner .img");
      var li = document.querySelectorAll(".banner ul li");
      // var num = 0;
      setInterval(() => {
        imgurl.src = this.img[this.num];
        this.url = imgurl.src;
        this.ind = this.num;
        this.num = this.num + 1;
        if (this.num > 3) {
          this.num = 0;
        }
        for (let i = 0; i < li.length; i++) {
          if (i == this.ind) {
            li[i].style.background = "#ccc";
          } else {
            li[i].style.background = "";
          }
        }
      }, 2000);
    },
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(0);
    },
    // 轮播图距顶部距离
    goheight() {
      let ban = document.getElementById("ban");
      if (ban) {
        let offheight = ban.getBoundingClientRect().top;
        if (offheight < 0) {
          this.$refs.header.navstyle(0);
        } else if (offheight == 0) {
          this.$refs.header.topstyle(0);
        }
      } else {
        return;
      }
    },
  },
  watch: {
    url(newval) {
      console.log(newval);
      console.log(this.serve + "----------");
      if (newval == "https://" + this.serve + img1) {
        this.android = true;
        this.start = false;
        this.about = false;
        this.more = false;
      }
      if (newval == "https://" + this.serve + img2) {
        this.android = false;
        this.start = true;
        this.about = false;
        this.more = false;
      }
      if (newval == "https://" + this.serve + img3) {
        this.android = false;
        this.start = false;
        this.about = false;
        this.more = true;
      }
      if (newval == "https://" + this.serve + img4) {
        this.android = false;
        this.start = false;
        this.about = true;
        this.more = false;
      }
    },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".banner");
    let main = document.querySelector(".main");
    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    main.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });

    let that = this;

    decide_router(that);
    this.autoplay();
    this.hover();
    this.hoverout();
    this.over();
    this.out();
    changewidth();
    getwidth();
    this.scroll();
    window.addEventListener("scroll", this.goheight, true);
    let imgurl = document.querySelector(".banner .img");
    this.url = imgurl.src;
    let serve = window.location.host;
    this.serve = serve;

    console.log(window.location);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.goheight);
  },
};
</script>
<style scoped>
@import url("../../assets/css/index/index.css");
</style>
