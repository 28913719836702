const findid = (m, n) => {

    let id = localStorage.getItem("id");
    let element = document.getElementById(id);
    let num = localStorage.getItem("num");
    let path = localStorage.getItem('path');
    // console.log(m);
    if (element) {
        if (path == "/dataserve" || path == "/pingtaikaifa") {
            window.scrollTo({
                top: m[num] - 120,
                left: 0,
                behavior: "smooth", //重点在此
            })
        } else {
            window.scrollTo({
                top: m[num] + n - 50,
                left: 0,
                behavior: "smooth", //重点在此
            });
        }

        localStorage.setItem("id", "");
        localStorage.setItem("num", "");
    }
}

export default findid;