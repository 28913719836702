<template>
  <div class="index">
    <!-- 头部 -->
    <v-header ref="header"></v-header>

    <div
      class="dataserve"
      id="data-cai"
      style="margin:0 auto"
    >
      <div class="title_img">
        <img
          src="../../assets/img/户外数据采集顶图.png"
          alt=""
        >
        <div class="mmm">
          <h3 style="color:black">户外数据采集</h3>
          <p style="color:black">关注您的户外数据获取体验，为您提供外业工作前、中、后各环节的便捷辅助，帮助您专注数据本身，高效创造更多价值。</p>
        </div>

      </div>
      <div class="caiji_one">
        <h4 class="title">采集规划</h4>
        <p class="text">通过预设表单、点位，导入规划数据和下载离线地图，做足出发前的准备</p>
        <ul
          class="nav "
          style="margin-top:124px;margin-bottom: 0;"
        >
          <li style="margin-left:25px">
            <img
              src="../../assets/img/组 1012.png"
              alt=""
            >
            <h5>预设观察点位</h5>
            <p>外业工作前，您可通过不同颜色、符号及标注，提前标记您的目标点概况，以便在现场工作时聚焦重点目标。</p>
          </li>
          <li>
            <img
              src="../../assets/img/组 1016.png"
              alt=""
            >
            <h5>工程表单预设</h5>
            <p style="height:185px;width: 270px;">数据采集前，您可根据采集内容需求，收藏表单库中的规范表单或创建定制化表单，为即将开展的外业工作定好标准，方便您更加高效规范开展作业。</p>
          </li>
          <li>
            <img
              src="../../assets/img/组 1018.png"
              alt=""
            >
            <h5>规划路径导入</h5>
            <p>支持kml、kmz、shp、gpx格式图层文件的导入,便于您将筹划已久的目的地和路线规划轻松装入,即刻出发。</p>
          </li>
          <li style="margin-right:17px">
            <img
              src="../../assets/img/组 1021.png"
              alt=""
            >
            <h5>下载离线地图</h5>
            <p>目的地网络信号不好？不用担心。出行前下载好目的地的多种图源，确保网络信号微弱时也能查看清晰准确的信息，有备无患。</p>
          </li>

        </ul>
      </div>
      <div class="caiji_one">
        <h4 class="title">数据采集</h4>
        <p
          class="text"
          style="width:460px;margin-top: 23px;margin-bottom: 122px;"
        >定点还是“记路”，采集路上不仅有便捷的工具，还有便利的工程表单</p>
        <ul class="nav_caiji">
          <li style="width:280px">
            <img
              src="../../assets/img/组 1022.png"
              alt=""
            >
            <h5>精准打点</h5>
            <p>
              您可以通过移动地图控制需要打点记录的具体位置，也可以直接输入您的目的地经纬度，通过校对位置，快速锁定目标点。
            </p>
          </li>
          <li>
            <h5>便捷记录</h5>
            <p>
              有了提前预设的表单，您只需在打点记录时，选择需要记录的工程表，即可切换记录内容的选项，快速准确的录入重要信息。
            </p>
            <img
              src="../../assets/img/组 1056.png"
              alt=""
            >
          </li>
          <li>
            <img
              src="../../assets/img/组 1057.png"
              alt=""
            >
            <h5>实用工具</h5>
            <p>
              除了测距、测面、水平仪，还为您提供了专业的地质罗盘用于采集倾向倾角，另有一键锁屏协助工作。
            </p>
          </li>
          <li>
            <h5>轨迹记录</h5>
            <p>
              本没路，走的人多了，便是路。走过路过还需"记路"，轨迹记录为您提供"记路"功能，您还可以导出并分享“路”。
            </p>
            <img
              src="../../assets/img/组 1058.png"
              alt=""
            >
          </li>

        </ul>
      </div>

      <div class="caiji_one">
        <h4
          class="title"
          style="margin-top:140px;margin-bottom: 23px;"
        >数据整理</h4>
        <p
          class="text"
          style="width:460px;margin-bottom: 122px;margin-top: 0;"
        >户外工作忙完，回到室内就不要再忙了，查看、管理还是导出、分享，都不需要太多时间。</p>
      </div>
      <ul class="zhengli_nav">
        <li>
          <div class="left">
            <img
              src="../../assets/img/组 1031.png"
              alt=""
            >
          </div>
          <div
            class="right"
            style="margin-right:16px"
          >
            <h5>图层叠加查看</h5>
            <p>不论是表单里的记录，还是提前导入的规划，亦或是轨迹里的“记路”，您都可以在图层叠加里找到它们，并控制它们的显示顺序。</p>
          </div>
        </li>
        <li>
          <div
            class="right"
            style="margin-left:51px"
          >
            <h5>数据批量管理</h5>
            <p>您在表单中记录的点和在轨迹中记录的线，都可通过单选或批量的方式，修改其在地图上的显示样式。当然，您还可以补充或修改已有的记录信息，查看统计内容。</p>
          </div>
          <div class="left">
            <img
              src="../../assets/img/组 1044.png"
              alt=""
            >
          </div>

        </li>
        <li>
          <div class="left">
            <img
              src="../../assets/img/组 1055.png"
              alt=""
            >
          </div>
          <div
            class="right"
            style="margin-right:16px"
          >
            <h5>数据关联导出</h5>
            <p>记录回来还要对照片？no no，一键导出即可获得数据表+矢量文件+照片包，三份惊喜，而且照片已经给您整理好了。</p>
          </div>
        </li>
        <li style="margin-bottom:79px">
          <div
            class="right"
            style="margin-left:51px"
          >
            <h5>数据压缩分享</h5>
            <p>重要的数据需要备份，经典的记录需要分享。长按一下，压缩还是分享，点一下，就到位。</p>
          </div>
          <div class="left">
            <img
              src="../../assets/img/组 1051.png"
              alt=""
            >
          </div>

        </li>
      </ul>
    </div>

    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import { decide_router } from "../../unitils/decide_router.js";
export default {
  components: { vHeader, vFooter },
  data() {
    return {
      inner: null,
    };
  },
  methods: {
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(2);
    },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-cai");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(2);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(2);
            }
          } else {
            return;
          }
        },
        true
      );
    },
    // 二级导航样式
    secondstyle() {
      this.$refs.header.three_style(4);
    },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".dataserve");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    let that = this;
    decide_router(that);
    this.scroll();
    this.hover();
    this.hoverout();
    this.getHeight();
    this.secondstyle();
  },
};
</script>
<style scoped>
@import url("../../assets/css/dataserve/dataserve.css");
@import url("../../assets/css/pingtaikaifa/pingtaikaifa.css");
@import url("../../assets/css/datajiagong/datajiagong.css");
@import url("../../assets/css/huwaicaiji/huwaicaiji.css");
</style>
