<template>
  <div class="index">
    <!-- 头部 -->
    <v-header ref="header"></v-header>

    <div
      class="dataserve"
      id="data-shu"
      style="margin:0 auto"
    >
      <div class="title_img">
        <img
          src="../../assets/img/组 19391.png"
          alt=""
        >
        <div class="mmm">
          <h3>数据库建设</h3>
          <p>基于空间地理技术与数据库管理系统，为您提供逻辑结构完整，适用于不同场景的数据库，可帮助您对空间数据、栅格数据等进行高效、有序的管理。</p>
        </div>

      </div>
      <div class="jiagong">
        <ul class="jiagong_ul">
          <li>
            <h4>需求分析</h4>
            <p style="margin-bottom: 73px;">分析数据包含模块，确认各模块工作量，依据实际需求情况，制作合理高效的技术方案</p>
            <div class="jiagong_img">
              <img
                src="../../assets/img/组 1358.png"
                alt=""
              >
            </div>

          </li>
          <li>
            <h4 style="margin-top: 180px;">数据库建设</h4>
            <p style="width: 720px;margin-bottom: 60px;">基于需求分析结果，建立E-R模型并优化，再进行逻辑设计，确定数据的存储结构、设计数据的存储路径、确定数据的存放位置，配套数据字典</p>
            <div class="jiagong_img">
              <img
                src="../../assets/img/组 1505.png"
                alt=""
              >
            </div>

          </li>
          <li style="margin-bottom: 100px;">
            <h4 style="margin-top: 181px;">检测与成果提交</h4>
            <p style="height:62px;margin-bottom:60px">复查库逻辑与物理结构，同时进行数据质检。确认无误后提交成果</p>
            <div class="jiagong_img">
              <img
                src="../../assets/img/组 1361.png"
                alt=""
              >
            </div>

          </li>
        </ul>
      </div>
    </div>

    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import { decide_router } from "../../unitils/decide_router";
export default {
  components: { vHeader, vFooter },
  data() {
    return { inner: null };
  },
  methods: {
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(2);
    },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-shu");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(2);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(2);
            }
          } else {
            return;
          }
        },
        true
      );
    },
    // 二级导航样式
    secondstyle() {
      this.$refs.header.three_style(1);
    },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".dataserve");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    this.getHeight();
    this.scroll();
    this.hover();
    this.hoverout();
    let that = this;
    decide_router(that);
    this.secondstyle();
  },
};
</script>
<style scoped>
@import url("../../assets/css/dataserve/dataserve.css");
@import url("../../assets/css/pingtaikaifa/pingtaikaifa.css");
@import url("../../assets/css/datajiagong/datajiagong.css");
</style>
