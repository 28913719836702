<template>
  <div class="index">
    <!-- 头部 -->
    <v-header ref="header"></v-header>
    <div class="zhence" style="margin: 0 auto">
      <div class="zhence_title">
        <h3>第四记 GeoQuater 隐私政策</h3>
      </div>
    </div>
    <div class="zhence_text">
      <p class="p1">
        第四记由第四纪数字地球(北京)科技有限公司（以下简称“第四纪数字地球”或“本公司”）开发。您在使用及访问第四记提供的软件、网站、服务（统称为“第四记产品或服务”）时，我们可能会收集和使用您的相关信息。我们希望通过《第四记隐私政策》（以下简称“本政策”）向您说明我们在收集和使用您相关个人信息时对应的处理规则等相关事宜，以便更好地保障您的权益。
        请在使用第四记产品或服务前仔细阅读本《隐私政策》。一旦您开始使用第四记产品，即表示您了解并接受了本《隐私政策》中所述的全部内容，并同意受其约束。如果您不接受前述任何内容，请停止访问或使用第四记产品或服务。
      </p>
      <p class="p2">2023年04月12日更新</p>
      <p class="p2">
        如您对本隐私政策或相关事宜有任何疑问，您可随时通过发送邮件至GeoQuater@126.com或致电（010）6200
        - 1109与我们取得联系。
      </p>
      <p class="p3">本隐私政策将帮助您了解以下内容：</p>
      <p>1、我们如何收集和使用您的个人信息</p>
      <p>2、我们如何使用Cookie和同类技术</p>
      <p>3、用户信息的共享、转让、公开披露</p>
      <p>4、我们如何保存及保护您的个人信息</p>
      <p>5、您如何管理自己的信息</p>
      <p>6、适用法律与争议解决</p>
      <p>7、未成年人保护</p>
      <p>8、隐私政策的变更</p>
      <p>9、联系我们</p>
      <p class="h1">一、我们如何收集和使用您的个人信息</p>
      <p class="p4">
        在您使用第四记产品和服务时，我们需要/可能需要收集和使用的您的个人信息包含如下两种：
      </p>
      <p>
        1．为实现向您提供第四记产品和服务的基本功能，包括定位服务、地图服务、导航服务、记录服务（储存、导出、分享），您须授权我们收集、使用的必要信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务。
      </p>
      <p>
        2．为实现向您提供第四记产品和服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，不会影响您使用我们的基本功能。
      </p>
      <p class="h1">（一）我们收集和使用您的个人信息的功能和场景</p>
      <p class="p5">1．账号信息</p>
      <p>
        当您使用第四记产品和服务时，可以登录已注册的第四记帐号，目前第四记仅支持手机号码登陆，并需要进行验证码验证。当您使用手机号一键注册/登录时，我们会收集您的设备信息、手机号。注册/登录成功后您可自主选择是否完善您的个人主页信息（如头像、昵称、性别、生日、行业、所在地、签名）。
        当您与第四记联系时，为了帮助您解决问题或提供服务，我们可能会记录您与我们的沟通、通信/通话记录及相关内容（包括帐号信息、沟通内容、您为了证明相关事实的其他信息，或您留下的联系方式），为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时提供的相关信息，您反馈给我们问题信息、图片及邮件信息。
      </p>
      <p class="p5">2、位置信息</p>
      <p>
        当您使用我们提供基于位置提供的相关服务，并开启设备定位权限时，我们需要收集和处理您的动态位置信息、行踪轨迹。我们会使用各种方式进行定位，包括通过IP地址、卫星信号以及能够提供相关信息的其他传感、或接入第三方定位服务。我们会对第四记用户的位置信息进行定位计算，以确保用户在使用多人协同作业时可以更好的体验服务与功能，您可以通过系统授权关闭定位服务或系统权限，停止我们对您的位置信息的收集，但您可能将无法获得相关服务或功能，或者无法达到相关服务拟达到的效果。<br />
        当您使用多人协作和其他位置共享服务时，第四记会记录您与同工程成员的社交关系，并根据您的选择提供位置共享服务。成员昵称、设置信息、内容或工程名称会存储在我们的服务器中，因为存储是实现服务所必需的，在读取分享口令时您的剪切板功能将会被调用。
      </p>
      <p class="p5">3．应用列表读取</p>
      <p>
        当您使用我们提供的导航功能时，第四记会读取您的设备应用列表，并调用第三方导航应用（包括但不限于百度地图、高德地图、腾讯地图）。当您使用我们提供的分享功能时，第四记会读取您的设备应用列表，并调用第三方社交应用（包括但不限于微信、QQ、钉钉）。
      </p>

      <p class="p5">4、日志信息和发布内容</p>
      <p>
        当您使用第四记的搜索和信息展示服务时，我们的服务器会自动记录您的日志信息，包括您输入的搜索关键词信息（包括您通过语音交互输入的信息）和点击的链接，发布的报错消息、反馈和您上传的图片以及您的IP地址、设备信息、浏览器的类型和使用的语言、操作系统版本、网络运营商的信息、访问服务时间和收藏记录。请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至敏感信息，如您在记录时选择上传包含个人信息的图片，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。<br />
        当您使用的客户端软件频繁向我们进行报错或显示错误日志信息时，为了检查软件错误，给您提供更好的服务，我们会在您的设备上记录、收集您的配置信息、异常数据等用于诊断并完善产品及服务的文件
      </p>
      <p class="p5">5、使用说明</p>
      <p>
        我们将按照前述声明在服务中使用您的个人信息，如我们需要基于本政策未载明的其他用途或非特定目的收集或使用您的个人信息，我们会事先征求您的同意。
        我们将根据您的授权，在您使用第四记产品及服务期间使用上述信息。
        如我们停止运营第四记产品或服务，我们将及时停止您仅授权于该服务使用的个人信息，并将以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。
      </p>
      <p class="h1">（二）设备权限调用</p>
      <p class="p4">
        第四记在提供服务过程中，会调用您的一些设备权限，您可以在设备的设置功能或“隐私设置”中查看权限状态，并可自行选择开启或关闭部分或全部权限。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
        在不同设备中，权限显示方式及关闭方式有所不同，请您参照设备或系统使用说明与指引。您的设备系统可能会在必要信息授权的同时开放其他权限，第四记自身无法通过手动或自动设置关闭授权。
      </p>
      <p>权限申请与使用情况说明：</p>
      <p>
        1．为保障第四记产品功能实现与安全稳定运行目的，我们可能会申请或使用操作系统的相关权限；
      </p>
      <p>
        2．为保障您的知情权，我们通过下列列表将产品可能申请、使用的相关操作系统权限进行展示，您可以根据实际需要对相关权限进行管理；
      </p>
      <p>
        3．根据产品的升级，申请、使用权限的类型与目的可能会有变动，我们将及时根据这些变动对列表进行调整，以确保您及时获悉权限的申请与使用情况；
      </p>
      <p>
        4．请您知悉，我们为业务与产品的功能与安全需要，我们可能也会使用第三方SDK，这些第三方也可能会申请或使用相关操作系统权限；
      </p>
      <p>
        5．以下是第四记调用权限对应的业务功能、调用权限的目的以及调用权限前向您询问的情况。
      </p>
      <p>应用权限列表：</p>
      <table>
        <tr class="thead">
          <th>设备权限</th>
          <th>业务功能</th>
          <th>调用目的</th>
          <th>是否询问</th>
          <th>可否关闭</th>
        </tr>
        <tbody>
          <tr>
            <td>定位</td>
            <td>位置相关服务</td>
            <td>用于提供精确的定位记录、轨迹记录、高程获取等功能</td>
            <td>首次安装进行询问</td>
            <td>建议保留</td>
          </tr>
          <tr>
            <td>存储</td>
            <td>地图缓存和图片浏览、储存</td>
            <td>用于图片和地图缓存、地图下载、数据记录、照片保存等功能</td>
            <td>首次安装进行询问</td>
            <td>建议保留</td>
          </tr>

          <tr>
            <td>相机</td>
            <td>拍照记录、水印相机、反馈上报、头像上传、扫描等功能</td>
            <td>实现拍照上传、识别二维码、反馈情况、水印相机等功能</td>
            <td>调用相关功能询问</td>
            <td>可</td>
          </tr>
          <tr>
            <td>照片</td>
            <td>拍照记录、水印相机、反馈上报、头像上传等</td>
            <td>问题上报和反馈、图片数据记录、水印相机等功能</td>
            <td>调用相关功能询问</td>
            <td>可</td>
          </tr>
          <tr>
            <td>日历</td>
            <td>时间获取</td>
            <td>记录时间获取</td>
            <td>调用相关询问</td>
            <td>可</td>
          </tr>
        </tbody>
      </table>
      <p class="h1">（三）第三方SDK服务</p>

      <p>
        为了保障第四记产品或服务的正常运行、功能实现，使您能够使用和享受更多的服务及功能，我们会收集您的硬件型号、操作系统版本、设备配置、唯一设备标识符、网络设备硬件地址MAC、设备信息（包括IMEI/android
        ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI
        信息）、设备连接信息、设备状态信息、网络身份标识信息以及您安装的应用信息或运行中的进程信息，前述信息是提供互联网移动应用服务所必需的，基于不同的系统（安卓/iOS）设备信息表述文字有所不同。<br />
        我们可能会接入由第三方提供的软件开发包（SDK）。我们对会对接入的第三方SDK进行严格的安全监测，尽到审慎义务，以保护数据安全。<br />
        我们接入的第三方SDK主要服务于您以及其他的用户的需求，因此在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK。我们会及时在本说明中向您公开说明接入第三方SDK的最新情况，具体请以第三方SDK官方隐私政策为准。目前手机第四记APP接入的第三方SDK列明如下：
      </p>
      <p class="p5">1．友盟SDK</p>
      <p>
        我们的产品集成友盟+SDK，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android
        ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI
        信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。<br />
        公司名称：友盟同欣（北京）科技有限公司<br />
        接入目的：用于统计、分析数据，提供基础反作弊能力<br />
        应用方式：SDK接入<br />
        收集个人信息类型：设备信息（IMEI/MAC/Android
        ID/IDFA/OpenUDID/GUID/IP地址/SIM 卡 IMSI 信息等）<br />
        隐私权政策链接：https://www.umeng.com/policy
      </p>
      <p class="p5">2．华为定位服务SDK</p>
      <p>
        公司名称：华为软件技术有限公司 <br />
        接入目的：在用户授权后，快速、方便地获取用户设备的精准位置信息<br />
        应用方式：SDK接入 <br />
        收集个人信息类型：传感器信息、位置信息、华为帐号信息<br />
        隐私权政策链接：<br />
        <span style="width: 100%; word-wrap: break-word">
          https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001271400621</span
        >
      </p>

      <p class="p5">3．微信</p>
      <p>
        公司名称：深圳市腾讯计算机系统有限公司<br />
        接入目的：帮助用户将信息分享至微信<br />
        收集个人信息类型：设备信息（外部储存目录） <br />
        隐私权政策链接：<br />
        <span style="width: 100%; word-wrap: break-word"
          >https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</span
        >
      </p>

      <p class="p5">4．QQ</p>
      <p>
        公司名称：深圳市腾讯计算机系统有限公司 <br />
        接入目的：帮助用户将信息分享至QQ <br />
        收集个人信息类型：设备信息（外部储存目录）<br />
        隐私权政策链接：<br />
        <span style="width: 100%; word-wrap: break-word"
          >https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e</span
        >
      </p>
      <p class="p5">5．小米推送SDK</p>
      <p>
        公司名称：公司名称：北京小米移动软件有限公司 <br />
        接入目的：用于向您推送消息 <br />
        收集个人信息类型：设备标识(OAID、加密的Android
        ID)、推送消息内容、设备信息(设备厂商、型号、归属地、运营商名称等)、通知栏设置
        可能调用的权限：访问网络状态、获取设备信息、写入设备存储<br />
        隐私权政策链接：<br />
        <span style="width: 100%; word-wrap: break-word"
          >https://dev.mi.com/distribute/doc/details?pId=1534</span
        >
      </p>
      <p class="p5">6．微博</p>
      <p>
        公司名称：新浪网技术（中国）有限公司 <br />
        接入目的：帮助用户将信息分享至微博 <br />
        收集个人信息类型：设备信息（外部储存目录）<br />
        隐私权政策链接：<br />
        <span style="width: 100%; word-wrap: break-word"
          >https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE#.E9.9A.90.E7.A7.81.E7.9B.B8.E5.85.B3.E6.9D.A1.E6.AC.BE</span
        >
      </p>
      <p class="h1">（四）技术处理说明</p>
      <p>
        请您知悉，根据适用的法律，我们可能在收集您的个人信息后通过采取技术措施和必要措施进行处理，使得数据接收方无法重新识别特定个人，或对收集的信息进行去标识化地研究、统计分析和预测，用于改善服务或展示信息的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务。前述处理后的数据使用无需另行向您通知并征得您的同意。
      </p>
      <p class="h1">（五）其他</p>
      <p>
        请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵盖的其他服务，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程、协议约定的方式另行向您说明信息收集的范围与目的，并征得您的同意。
      </p>
      <p class="h1">二、我们如何使用Cookie和同类技术</p>
      <p class="p5">
        当您使用第四记产品或服务时，为使您获得更轻松的访问体验，我们可能会使用各种技术来存储信息，在此过程中可能会向您的设备发送一个或多个
        Cookie
        或匿名标识符。这么做是为了使您省去重复输入注册信息步骤，或帮助判断您的帐户安全。
      </p>
      <p class="p5">
        我们不会将Cookie用于本政策所述目的之外的任何用途，您可根据自己的偏好留存或删除Cookie。您可清除软件内保存的所有Cookie，当您手动清除后您的相关信息即已删除。
      </p>
      <p class="h1">三、用户信息的共享、转让、公开披露</p>
      <p class="p5">1、共享</p>
      <p>
        我们会以高度的勤勉义务对待您的信息，不会与第四记及其关联公司以外的任何公司、组织和个人提供或分享您的信息，但以下特定情形除外：
      </p>
      <p>a.事先获得您的明确授权或同意;</p>
      <p>b.根据法律法规规定或有权机关的指示提供用户的个人隐私信息;</p>
      <p>c.用户向第三方提供授权，公开自身个人隐私信息的;</p>
      <p class="p5">2、转让</p>
      <p>
        我们不会将您的个人信息转让、出售、出借、出租给除第四记及其关联公司外的公司、组织和个人，但以下特定情形除外:
      </p>
      <p>a.事先获得您的明确授权或同意;</p>
      <p>
        b.根据法律法规、法律程序的要求或强制性的政府要求或司法裁定第四记必须提供;
      </p>
      <p>c.符合您签署的其它法律文件(如本条款）的约定而使用;</p>
      <p>
        d.在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私权政策的约束，否则我们会要求该公司、组织重新向您征求授权同意。
      </p>
      <p class="p5">3、公开披露</p>
      <p>我们仅在以下情况，公开披露您的个人信息:</p>
      <p>a.已获得您的明确同意;</p>
      <p>b.在法律法规、法律程序、诉讼或政府主管部门强制性要求下。</p>
      <p class="p5">4、例外</p>
      <p>
        在以下情形中，共享、转让、公开披露个人信息无需征得个人信息主体的授权与同意:
      </p>
      <p>a.与国家安全、国防安全有关的;</p>
      <p>b.与公共安全、公共卫生、重大公共利益有关的;</p>
      <p>c.与犯罪侦查、起诉、审判和判决执行等有关的;</p>
      <p>
        d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;
      </p>
      <p>e.个人信息主体自行向社会公众公开的个人信息;</p>
      <p>
        f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <p class="h1">四、我们如何保存及保护您的个人信息</p>
      <p class="p5">
        1、我们将采取合理的技术措施和其它必要措施，以确保用户信息的安全，并努力防止用户信息泄露、丢失或遭受破坏.
      </p>
      <p>a.使用加密技术来确保您的数据在传输过程中保持私密性;</p>
      <p>
        b.审查我们在收集、存储和处理信息方面的做法（包括实体安全措施)，以防未经授权的人员访问我们的系统;
      </p>
      <p class="p5">
        2、尽管严格遵守法律法规要求的标准并已经采取了上述合理有效措施，但由于技术的限制以及可能存在的各种恶意手段等我们无法控制的原因，第四记无法始终保证信息绝对安全，我们将尽力确保您提供给我们的个人信息的安全性。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。
      </p>
      <p class="p5">
        3、我们会制定应急处理预案，并在发生用户信息安全事件时立即应急预案，努力阻止该等安全事件的影响和后果扩大。在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
      </p>
      <p class="h1">五、您如何管理自己的信息</p>
      <p>
        第四记非常尊重您对个人信息的关注，并为您提供了管理个人信息的方法。您有权利查询、更正、管理、删除自己的信息并保护自己的隐私和安全。我们会在符合法律法规要求的情形下响应您的请求：
      </p>
      <p class="h1">（一）查询、更正和补充</p>
      <p>
        您在使用我们的产品和服务时，会被要求提供您真实的个人信息，您应当对您所提供信息的真实性、合法性、有效性及完整性负责，并及时更新和维护您的个人信息，以保证信息的真实、合法和有效。<br />
        除法律法规规定的例外情况，无论您何时使用我们的服务，我们都会力求让您顺利访问自己的个人信息。您可以通过如下方式行使您的查询、更正和补充权利：
      </p>
      <p>
        1、账户信息：登录第四记，点击“我的”-“头像”，可查询、修改或补充您的个人资料；点击“我的”-“设置”-“账号管理”，可更换绑定手机号、修改您的密码。您可以随时管理您的个人信息，例如您的个人账户注册信息。您可以自行从第四记产品或服务账号中删除您的内容或个人信息，或要求我们删除或修改信息。
      </p>
      <p>
        2、使用信息：您可以在“我的”板块中查阅、管理您等的工程记录、轨迹路线、标绘内容、导入或导出文件。如在第四记APP-“我的”点击“我的工程”、“我的轨迹”、“我的标绘”等模块，就可以看到您的相关信息内容。
      </p>
      <p class="h1">（二）删除</p>
      <p class="p5">
        1、您可以通过如下方式行使您的删除权利，或申请注销账户删除您的全部信息：
      </p>
      <p class="zimu">
        <span>a.</span>
        <span>通过“搜索框”-“清空历史记录”删除搜索历史；</span>
      </p>
      <p class="zimu">
        <span>b.</span>
        <span>通过“我的”-“我的工程”-“个人工程更多操作”删除工程内容；</span>
      </p>
      <p class="zimu">
        <span>c.</span>
        <span
          >通过“我的”-“我的工程”-“协作工程更多操作”-“协作设置”-“解散/退出协作组”删除多人协作工程；</span
        >
      </p>
      <p class="zimu">
        <span>d.</span>
        <span>通过“我的”-“我的工程”-“表单更多操作”删除表单内容；</span>
      </p>
      <p class="zimu">
        <span>e.</span>
        <span
          >通过“我的”-“我的工程”-“表单更多操作”-“移除云端”删除已同步至云端的记录内容；</span
        >
      </p>
      <p class="zimu">
        <span>f.</span>
        <span>通过“我的”-“我的工程”-“表单更多操作”删除表单内容；</span>
      </p>
      <p class="zimu">
        <span>g.</span>
        <span
          >通过“我的”-“我的工程”-“记录表单”-“记录列表”-“编辑”删除记录内容；</span
        >
      </p>
      <p class="zimu">
        <span>h.</span>
        <span>通过“我的”-“我的轨迹”-“更多操作”删除文件夹/轨迹内容；</span>
      </p>
      <p class="zimu">
        <span>i.</span>
        <span
          >通过“我的”-“我的轨迹”-“云端轨迹”-“更多操作”删除云端文件夹/轨迹内容；</span
        >
      </p>
      <p class="zimu">
        <span>j.</span>
        <span>通过“我的”-“我的标绘”-“更多操作”删除文件夹/标绘内容；</span>
      </p>
      <p class="zimu">
        <span>k.</span>
        <span
          >通过“我的”-“我的标绘”-“云端标绘”-“更多操作”删除云端文件夹/标绘内容；</span
        >
      </p>
      <p class="zimu">
        <span>l.</span>
        <span>通过“我的”-“我的表单”-“更多操作”删除自定义表单模版；</span>
      </p>
      <p class="zimu">
        <span>m.</span>
        <span
          >通过“我的”-“我的表单”-“云端表单”-“更多操作”删除云端自定义表单模版；</span
        >
      </p>

      <p class="zimu">
        <span>n.</span>
        <span>通过“我的”-“导入数据”-“更多操作”删除文件夹/导入内容；</span>
      </p>
      <p class="zimu">
        <span>o.</span>
        <span>通过“我的”-“离线地图”-“更多操作”删除已下载离线地图；</span>
      </p>
      <p class="zimu">
        <span>p.</span>
        <span>通过“我的”-“标准文档”-“文档管理”删除已下载标准文档；</span>
      </p>
      <p class="zimu">
        <span>q.</span>
        <span>通过“我的”-“导出管理”-“更多操作”删除已导出的内容；</span>
      </p>
      <p class="zimu">
        <span>r.</span>
        <span>通过“记录内容”-“批注”删除批注发布的内容；</span>
      </p>
      <p class="zimu">
        <span>s.</span>
        <span>通过“我的”-“设置”清理地图缓存。</span>
      </p>

      <p class="p5">
        2、在以下情形中，您可以联系客服向我们提出删除个人信息的请求：
      </p>
      <p>a.我们处理个人信息的行为违反法律法规；</p>
      <p>b.我们收集、使用您的个人信息，却未征得您的明确同意；</p>
      <p>c.我们处理个人信息的行为严重违反了与您的约定；</p>
      <p>d.我们的处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
      <p>e.我们停止提供产品或者服务，或者保存期限已届满；</p>
      <p>
        若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
      </p>
      <p>
        当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除
      </p>

      <p class="h1">（三）注销账户</p>
      <p>
        您可以通过“我的-设置-账号管理-注销账号”提交账号注销申请。为了保护您或他人的合法权益，我们需要在为您注销账号前验证您的身份。<br />
        在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除或匿名化处理您的个人信息。
      </p>
      <p class="h1">六、自启动和关联启动说明</p>
      <p class="p5">
        1、为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用（自启动）行为，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。
      </p>
      <p class="p5">
        2、当您打开第四记内容类推送消息，在征得您的明确同意后，会跳转第四记APP打开相关内容。在末征得您同意的情况下，则不会有自启动或关联启动。
      </p>
      <p class="p5">
        3、当您打开第四记App内部下载的文件后，会关联启动第三方APP。
      </p>
      <p class="h1">七、适用法律与争议解决</p>
      <p class="p5">1、适用法律</p>
      <p>
        本政策的执行、解释及争议的解决均适用中华人民共和国法律，且不考虑任何冲突法。
      </p>
      <p class="p5">2、争议解决</p>
      <p>
        您和第四记就本政策内容或其执行发生任何争议的，双方应友好协商解决;如双方无法协商解决争议时，双方同意应将争议提交至被告住所地人民法院裁决。
      </p>
      <p class="h1">八、未成年人保护</p>
      <p class="p5">1、我们非常重视未成年人的个人信息保护</p>
      <p>
        如您为未成年人，请务必要求您的监护人仔细阅读本隐私政策，并请您在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。
      </p>
      <p class="p5">2、补救措施</p>
      <p>
        a.如果您未能取得您监护人的同意或您未同意您的被监护人使用我们的服务并向我们提供信息，请您或被监护人立即停止使用我们的服务并及时与我们取得联系。
      </p>
      <p>
        b.在收到您的书面通知而知晓，第四记在未事先获得监护人同意的情况下收集了未成年人的个人信息时，第四记会立即停止相关信息收集及使用。
      </p>
      <p class="h1">九、隐私政策的变更</p>
      <p>
        本政策将根据业务模式的调整或更新不定期进行修订，该修订构成本隐私政策的一部分。未经您的明确同意，我们不会削减您按照本隐私政策所享有的权利。我们会通过合理的方式发布隐私政策所做的变更。
      </p>
      <p>
        如果变更的条款会削减您的权利或增加您的义务(即发生“重大变更”)，我们将会采取更具有提示作用的方式通知您，如在产品或服务的主页面或向您发送电子邮件等方式。若您继续使用我们的产品或服务，即表示您同意修订后的隐私政策。
      </p>
      <p>本政策所指的重大变更包括但不限于:</p>
      <p>
        1、我们的服务模式发生重大变化，如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </p>
      <p>2、个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>3、您参与个人信息处理的权利及其行使方式发生重大变化；</p>
      <p>4、我们的联络方式及投诉渠道发生变化。</p>
      <p class="h1">十、联系我们</p>
      <p>
        当您有任何疑问、意见或建议时，可以通过电话号码(010)
        6200-1109与我们联系，或将您的问题发送至第四记GeoQuater@126.com我们将尽快审核所涉问题，并在验证您的用户身份后的15个工作日内予以回复。
      </p>
      <p class="p10">第四纪数字地球（北京）科技有限公司</p>
    </div>
    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
export default {
  components: { vHeader, vFooter },
  data() {
    return { inner: null };
  },
  methods: {
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout();
    },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".zhence");
    let table = document.querySelector(".zhence_text");
    foot.style.width = inner + "px";
    table.style.width = 1200 + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    this.scroll();
    this.hover();
    this.hoverout();
  },
};
</script>
<style scoped>
@import url("../../assets/css/zhence/zhence.css");
</style>
