<template>
  <div
    class="footer"
    style="margin:0 auto"
  >
    <div
      class="footer-top"
      style="margin-top:0"
    >
      <div class="left">
        <ul class="first">
          <li style="fontSize:18px;cursor:default;">业务板块</li>
          <li @click="dataserve">数据服务</li>
          <li @click="pingtaikaifa">开发平台服务</li>
          <li @click="yidongapp">第四记APP</li>
          <li @click="kongjianpingtai">第四记空间平台</li>
        </ul>
        <ul
          style="margin-left:72px"
          class="two"
        >
          <li style="fontSize:18px;cursor:default;">应用产品</li>
          <li @click="yidongapp">第四记APP</li>
          <li @click="kongjianpingtai">第四记空间平台</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
        </ul>
        <ul
          style="margin-left:72px"
          class="three"
        >
          <li style="fontSize:18px;cursor:default;">服务与支持</li>
          <li @click="guanyu">关于我们</li>
          <li @click="zhence">隐私政策</li>
          <li @click="xieyi">用户协议</li>
          <li>&nbsp;</li>
        </ul>
      </div>
      <div class="right">
        <ul
          style="margin-right:58px;"
          class="one"
        >
          <li>联系电话：010-6200 1109 </li>
          <li>QQ群：1044251209</li>
          <li>Email: GeoQuater@126.com</li>
          <li>地址：北京市朝阳区北辰西路峻峰华亭D座</li>
          <li>&nbsp;</li>
        </ul>
        <ul
          style="margin-right:14px"
          class="two"
        >
          <li><img
              src="../../assets/img/组 1939.png"
              alt=""
            ></li>
          <li class="gongzhonghao">
            <img
              style="width:18px;height:18px"
              src="../../assets/img/微信.png"
              alt=""
            > <span style="margin-left:8px">公众号</span>
          </li>

        </ul>
        <ul class="three">
          <li><img
              src="../../assets/img/组 1938.png"
              alt=""
            ></li>
          <li class="guanwei">
            <img
              style="width:18px;height:18px"
              src="../../assets/img/微博.png"
              alt=""
            > <span style="margin-left:8px">官微</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="center">
        <p
          style="cursor:pointer"
          class="p1"
          @click="beian"
        >京ICP备2020040701号-1</p>
        <p class="p2"> ©2020-2023 第四纪数字地球（北京）科技有限公司版权所有</p>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    beian() {
      window.open("https://beian.miit.gov.cn/");
    },
    yidongapp() {
      this.$router.push("/yidongapp");
    },
    kongjianpingtai() {
      this.$router.push("/kongjianpingtai");
    },
    dataserve() {
      this.$router.push("/dataserve");
    },
    pingtaikaifa() {
      this.$router.push("/pingtaikaifa");
    },
    guanyu() {
      this.$router.push("/guanyu");
    },
    zhence() {
      this.$router.push("/zhence");
    },
    xieyi() {
      this.$router.push("/xieyi");
    },
  },
  mounted() {},
};
</script>
<style scoped>
@import url("../../assets/css/index/index.css");
</style>