<template>
  <div class="index">
    <!-- 头部 -->
    <v-header ref="header"></v-header>

    <div
      class="dataserve"
      id="data-zhi"
      style="margin:0 auto"
    >
      <div class="title_img">
        <img
          src="../../assets/img/组 19391.png"
          alt=""
        >
        <div class="mmm">
          <h3>数据制图</h3>
          <p>借助于图形化手段，可帮助您清晰有效地传达与沟通信息；合理的数据展现方式，可帮助您轻松洞悉万千数据背后的无限价值。</p>
        </div>

      </div>
      <div class="jiagong">
        <ul class="jiagong_ul">
          <li>
            <h4>专题图需求分析</h4>
            <p style="margin-bottom: 82px;">深入分析专题图制图需求，确认要素层数量与图幅范围，明确专题图件制作标准</p>
            <div class="jiagong_img">
              <img
                src="../../assets/img/组 1371.png"
                alt=""
              >
            </div>

          </li>
          <li>
            <h4 style="margin-top: 208px;">源数据加工</h4>
            <p style="width: 720px;margin-bottom: 82px;">集成制图原始空间数据，处理空间拓扑等问题，规范化各要素层属性信息，按一定的标准和规则将处理后的数据录入数据库</p>
            <div class="jiagong_img">
              <img
                src="../../assets/img/组 1372.png"
                alt=""
              >
            </div>

          </li>
          <li>
            <h4 style="margin-top: 193px;">综合整饰</h4>
            <p style="height: 62px;margin-bottom: 60px;">在制图工程文件中，严格按照国标与行标相关要求进行符号渲染、注记调整、布局优化</p>
            <div class="jiagong_img">
              <img
                src="../../assets/img/组 1368.png"
                alt=""
              >
            </div>

          </li>
          <li>
            <h4 style="margin-top: 181px;">成果提交</h4>
            <p style="margin-bottom: 110px;">多维度复查图件质量，包括准确性、专业性和美观性，并做必要修改</p>
          </li>
        </ul>
      </div>
    </div>

    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import { decide_router } from "../../unitils/decide_router";
export default {
  components: { vHeader, vFooter },
  data() {
    return { inner: null };
  },
  methods: {
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(2);
    },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-zhi");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(2);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(2);
            }
          } else {
            return;
          }
        },
        true
      );
    },
    // 二级导航样式
    secondstyle() {
      this.$refs.header.three_style(2);
    },
  },
  mounted() {
   let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".dataserve");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    this.scroll();
    this.hover();
    this.hoverout();
    this.getHeight();
    let that = this;
    decide_router(that);
    this.secondstyle();
  },
};
</script>
<style scoped>
@import url("../../assets/css/dataserve/dataserve.css");
@import url("../../assets/css/pingtaikaifa/pingtaikaifa.css");
@import url("../../assets/css/datajiagong/datajiagong.css");
</style>
