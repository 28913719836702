const decide_router = (a) => {
    if (a.$route.path == '/index') {
        a.$refs.header.active = true;
    } else {
        a.$refs.header.active = false;
    }
    if (a.$route.path == '/guanyu') {
        a.$refs.header.act_guanyu = true;
    } else {
        a.$refs.header.act_guanyu = false;
    }
    if (a.$route.path == '/dataserve' || a.$route.path == '/pingtaikaifa' || a.$route.path == '/yidongapp' || a.$route.path == '/kongjianpingtai') {
        a.$refs.header.act_serve = true;
    } else {
        a.$refs.header.act_serve = false;
    }
    if (a.$route.path == '/datajiagong' || a.$route.path == '/shujukujianshe' || a.$route.path == '/shujuzhitu' || a.$route.path == '/jiejuepingtaikaifa' || a.$route.path == '/huwaicaiji') {
        a.$refs.header.act_jiagong = true;
    } else {
        a.$refs.header.act_jiagong = false;
    }
}







// 也页面宽度变化跳转
const changewidth = () => {

    window.onresize = () => {
        let screenWidth = document.body.clientWidth;
        if (screenWidth < 1100) {
            window.location.href = "#";
        }
    };

}


const getwidth = () => {
    let screenWidth = document.body.clientWidth;
    // console.log(screenWidth);
    if (screenWidth < 1100) {
        window.location.href = "#";
    }
}
export { decide_router, changewidth, getwidth }