<template>
  <div class="index">
    <!-- 头部 -->
    <v-header ref="header"></v-header>

    <div
      class="guanyu"
      id="data-about"
      style="margin:0 auto"
    >
      <div class="guanyu_img">
        <img
          src="../../assets/img/交叉 5.png"
          alt=""
        >
        <h4>价值/愿景</h4>
        <p class="p1">记录无止境</p>
        <p class="p2">专业价值 服务未来</p>
      </div>
      <div class="our">
        <h5 class="title">我们能做什么</h5>
        <p class="text">为企业用户提供数据加工、数据库建设及数据制图等数据服务，为户外工作者提供便捷的数据采集服务，根据客户需求开发工程/科研成果展示平台、数据管理平台、数据共享授权平台等。</p>
      </div>
      <ul class="num">
        <li>
          <div>
            <i>40</i>
            <span>+</span>
          </div>
          <h6>合作企业</h6>
        </li>
        <li>
          <div>
            <i>20</i>
            <span>+</span>
          </div>

          <h6>覆盖省份</h6>
        </li>
        <li>
          <div>
            <i>100</i>
            <span>+</span>
          </div>

          <h6>提供服务</h6>
        </li>
        <li>
          <div>
            <i>A</i>
            <span>+</span>
          </div>

          <h6>用户满意度</h6>
        </li>
      </ul>
      <div class="join_us">
        <div class="jieshao">
          <div class="about">
            <h5>关于我们</h5>
            <p class="eng">About us</p>
            <p class="first">第四纪数字地球（北京）科技有限公司成立于2020年8月，团队经过近年发展，目前专业技术成员近二十人，地球科学领域合作专家十余位。团队致力于发展先进可靠的数据及计算机技术，为客户提供集数据加工、数据库建设、制图与可视化、平台开发、数据采集于一体的，地球科学数据产出应用解决方案。</p>
            <p class="second">公司当前主要业务覆盖全国二十余省份，自研产品“第四记-户外记录app”及“第四记空间平台”已陆续上线运营，旨在为用户提供先进的数据采集管理工具，推动全行业户外工作中数据产出的效率与质量。
            </p>
            <p class="third">在信息采集、显示、交互、管理、输出等环节为用户提供多来源、多功能、多场景的位置应用价值。公司秉承“专业价值，服务未来”的企业愿景，以专注的思考、敏锐的视角、高效的协作、积极的行动为这个世界提供专业可靠的价值服务。</p>
          </div>
          <div class="join">
            <div class="top">加入我们</div>
            <div class="middle">JOIN US</div>
            <i></i>
            <p>我们衷心地希望有识之士加入到我们的团队，有关招聘的相关内容，敬请关注本页动态~</p>
            <h6 @click="lookmore">
              <span title="暂无招聘计划">查看更多</span>

              <div>
                <img
                  src="../../assets/img/1939.png"
                  alt=""
                >
                <img
                  src="../../assets/img/1939.png"
                  alt=""
                >
              </div>
            </h6>

          </div>
        </div>
      </div>
      <div class="our">
        <h5
          class="title"
          style="margin-top:120px;margin-bottom:37px"
        >我们的团队</h5>
        <p class="text">我们作为新生的专业团队，年轻而充满朝气，具有创新意识并勇于迎接挑战</p>
        <p class="text">我们将以专注的思考、敏锐的视角、高效的协作、积极的行动为您提供专业可靠的服务</p>

      </div>
      <div class="our">
        <h5
          class="title"
          style="margin-top:120px"
        >找到我们</h5>
        <p class="text">北京市朝阳区北辰西路峻峰华亭D座</p>
        <div
          id="map"
          @click="map"
          style="margin-top:35px"
        >
          <img
            src="../../assets/img/地图.png"
            alt=""
          >
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import { decide_router } from "../../unitils/decide_router";
export default {
  components: { vHeader, vFooter },
  data() {
    return { inner: null };
  },

  methods: {
    lookmore() {
      this.$message("暂无招聘计划");
    },
    map() {
      window.open(
        "https://uri.amap.com/marker?position=116.387106,39.984046&name=峻峰华亭D座"
      );
    },
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(3);
    },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-about");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(3);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(3);
            }
          } else {
            return;
          }
        },
        true
      );
    },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".guanyu");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    let that = this;
    decide_router(that);
    this.scroll();
    this.hover();
    this.hoverout();
    this.getHeight();
  },
};
</script>
<style scoped>
@import url("../../assets/css/dataserve/dataserve.css");
@import url("../../assets/css/guanyu/guanyu.css");
</style>
