import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index/Index.vue'
import Dataserve from "../views/dataserve/Dataserve.vue"
import Pingtaikaifa from "../views/pingtaikaifa/Pingtaikaifa.vue"
import Datajiagong from "../views/datajiagong/Datajiagong.vue"
import Shujukujianshe from "../views/shujukujianshe/Shujukujianshe.vue"
import Shujuzhitu from "../views/shujuzhitu/Shujuzhitu.vue"
import Jiejuepingtaikaifa from "../views/jiejuepingtaikaifa/Jiejuepingtaikaifa.vue"
import Huwaicaiji from "../views/huwaicaiji/Huwaicaiji.vue"
import Guanyu from "../views/guanyu/Guanyu.vue"
import Zhence from "../views/zhence/Zhence.vue"
import Xieyi from "../views/xieyi/Xieyi.vue"
import Kongjianpingtai from "../views/kongjianpingtai/Kongjianpingtai.vue"
import Yidongapp from "../views/yidongapp/Yidongapp.vue"
Vue.use(VueRouter)
    //解决路由地址重复的报错问题(一劳永逸)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    { path: "/", redirect: "/index" },
    {
        path: '/index',
        name: 'Index',
        component: Index
    },
    // 产品业务
    {
        path: "/dataserve",
        name: "Dataserve",
        component: Dataserve
    },
    {
        path: "/pingtaikaifa",
        name: "Pingtaikaifa",
        component: Pingtaikaifa
    },
    {
        path: "/yidongapp",
        name: "Yidongapp",
        component: Yidongapp
    },
    {
        path: "/kongjianpingtai",
        name: "Kongjianpingtai",
        component: Kongjianpingtai
    },
    // 解决方案
    {
        path: "/datajiagong",
        name: "Datajiagong",
        component: Datajiagong
    },
    {
        path: "/shujukujianshe",
        name: "Shujukujianshe",
        component: Shujukujianshe
    },
    {
        path: "/shujuzhitu",
        name: "Shujuzhitu",
        component: Shujuzhitu
    },
    {
        path: "/jiejuepingtaikaifa",
        name: "Jiejuepingtaikaifa",
        component: Jiejuepingtaikaifa
    },
    {
        path: "/huwaicaiji",
        name: "Huwaicaiji",
        component: Huwaicaiji
    },
    {
        path: "/guanyu",
        name: "Guanyu",
        component: Guanyu
    },
    // 协议政策
    {
        path: "/zhence",
        name: "Zhence",
        component: Zhence
    },
    {
        path: "/xieyi",
        name: "Xieyi",
        component: Xieyi
    }


]

const router = new VueRouter({
    routes
})
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
export default router