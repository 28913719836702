<template>
  <div class="index">
    <!-- 头部 -->
    <v-header ref="header"></v-header>
    <div
      class="zhence"
      style="margin:0 auto"
    >
      <div class="zhence_title">
        <h3>第四记 GeoQuater 用户协议</h3>
      </div>
    </div>
    <div class="zhence_text">
      <p class="h1">一、重要须知</p>
      <p class="pp1"> 1、第四记由第四纪数字地球(北京)科技有限公司(以下简称“第四纪数字地球”或“本公司”)开发。您在使用及访问第四记提供的软件、网站、服务（统称为“第四记产品或服务”）前，请您务必认真阅读并理解本《用户协议》中各项条款，包括但不限于免除或者限制第四纪数字地球责任及对用户的权利限制条款。 </p>
      <p class="pp1">2、一旦您开始使用第四记产品，即代表您已理解并完全接受本用户协议及系列声明，本用户协议及第四记系列声明将构成对您〔和您代表的其他主体〕具有约束力的法律文件，同时还表明您已完全同意并承认第四记已经履行了相关的提示和通知义务。您确保您有充分的权力、权限及能力以同意及履行本用户协议。如果您代表其他主体同意本用户协议，您确保您已获得充分的法律授权以使您代表的其他主体接受本用户协议的约束。如果您未获得充分的授权，请确保由其他主体的授权人士同意本用户协议。如果您不同意本用户协议，请勿访问或使用第四记产品或服务。</p>
      <p class="pp1"> 3、如果您未满14周岁，请在法定监护人的陪同下阅读本用户服务条款，并特别注意未成年人使用的相关条款。</p>
      <p class="pp1">4、第四记有权不时对本用户服务条款进行修订，如果本用户服务条款发生变动，第四记将在第四记服务的相关软件及网站上公布修订后的用户协议。您理解并同意，如果您在修订后的用户协议公布并生效后继续使用第四记产品或服务，即视为您已经理解并同意修订后的用户协议。
      </p>
      <p class="h1">二、使用第四记产品或服务</p>
      <p class="p5">1、用户注册</p>
      <p>为了使用“第四记产品或服务”，用户需要注册一个“第四记平台”账号。出于安全性的考虑，“第四记平台”账号应当使用手机号码绑定注册。请用户使用尚未与“第四记平台”账号绑定的手机号码，以及未被本公司根据本协议封禁的手机号码注册“第四记平台</p>
      <p>您在注册使用第四记平台之前，应仔细认真阅读并理解本协议的内容和含义。当您填写信息、提交文件完成注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容。如您不同意接受本协议或其中任何条款约定，您可立即放弃注册程序。</p>
      <p>鉴于“第四记平台”账号的绑定注册方式，本公司在用户选用特定的注册方式时将会自动提取用户手机号码及手机设备识别码等信息用于注册。在用户注册及使用“第四记平台”时，本公司需要搜集能识别用户身份的个人信息以便本公司可以在必要时联系用户，或为用户提供更好的使用体验。本公司搜集的信息包括但不限于用户的姓名、性别、年龄、地址、所属行业、活动范围、运动轨迹图等。</p>
      <p>第四记平台上的部分产品系基于地理位置的移动应用产品，故用户完成注册即表明用户同意本公司提取、公开及使用用户的地理位置信息。如用户需要终止向第三方公开其地理位置信息，可自行在软件或网站后台进行设置。如有不懂的地方，可以直接与我们的客服进行联系。</p>
      <p>用户理解并同意，因业务发展需要，本公司保留单方面对“第四记平台”的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、终止或撤销的权利，用户需承担此风险。本公司尽可能在服务发生变更或终止前事先通知用户，但不视为义务。</p>
      <p class="p5">2、用户管理</p>
      <p>“第四记平台”账号的所有权归本公司所有，用户只是获得了“第四记平台”账号的使用权，该使用权属于初始申请注册人,禁止赠与、借用、租用、转让或售卖。本公司有权任何时候回收用户的“第四记平台”账号。</p>
      <p>用户可以更改、删除“第四记平台”账号上的个人资料、注册信息及传送内容等，用户需承担由此导致的重要信息误删与暴露风险。</p>
      <p>用户有责任妥善保管注册账号信息及账号密码的安全，以保证自己的权益不受侵犯。因用户保管不善而导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册账号以及密码下的行为承担法律责任。当用户怀疑自己的账号被盗或发现他人偷盗别人的账号，用户应立即通知本公司。</p>
      <p>用户如果违反本协议中的任何条款，本公司有权依据协议中断或终止对违约用户“第四记平台”账号提供服务。</p>
      <p class="p5">3、使用许可</p>
      <p>为第四记提供服务及您使用第四记产品的目的，第四记在此授予您一项个人的、不可转让的、不可转授权的、非排他性的、可撤销的普通使用许可，以使得您能够以本用户协议许可的方式使用第四记相关服务，用于您个人的非商业用途。您不得，并且保证您不允许其他人为商业目的而使用第四记服务，从而直接或间接获得任何形式的收益。如果您有商业需要，请您及时联系第四记，事先获得第四记的书面许可，并与第四记另行签订书面协议。</p>
      <p>本用户服务条款未明确的其他一切权利归第四记所有，本用户服务条款未授予您任何其他未明示的权利。如您使用其他权利，您需要及时联系第四记，事先获得第四记的书面许可，并与第四记另行签订书面协议。</p>
      <p class="p5">4、费用收取</p>
      <p>第四记在提供服务时，会对使用部分服务的用户收取一定的费用。在此情况下，第四记会在相关界面上做明确的提示。如果用户拒绝支付该等费用，则不能使用相关的服务。</p>
      <p class="p5">5、第三方服务</p>
      <p>第四记的部分信息（软件跳转至导航、地图资源下载、在线专题图源等）来自于第三方或用户的提供，地图数据来自于各地图厂商的开放式API接口，第四记平台没有任何相关的地图版权且任何信息都存在滞后或会被更新的可能，因此第四记不能保证在任何时候任何信息都准确无误。您应自行谨慎判断确定相关服务及/或信息的真实性、合法性和有效性，务必详细阅读各地图厂商的使用条款，并自行承担因此产生的责任与损失。您理解并同意，您在使用第三方服务时，应仔细阅读并遵守第三方的服务条款、隐私政策或其他第三方要求您遵守的任何法律文件。</p>
      <p>为了保障您的利益，请您在使用第三方服务前审慎判断并决定是否使用。您理解并同意，您是否使用第三方的产品或服务均由您自行判断、自愿选择，您应了解并清楚由于使用第三方服务所可能引发的任何风险和后果。如果您由于使用第三方服务而发生任何纠纷、后果、损失或其他问题，请您与第三方协商解决，第四记对此不承担责任，但会向您提供法律法规要求的必要且必须的信息或协助。</p>
      <p>你理解并清楚，使用第三方服务可能需要您向第三方支付费用，如果您不同意支付该费用，请勿使用此类服务。您理解并清楚，使用部分第三方服务，需要您向第三方提供其要求的个人信息，一旦您使用此类服务，即表示您同意第四记将您相应的个人信息发送至第三方，以使其能够向您提供服务。第四记对于第三方的信息收集、信息使用、信息保护及其他有关个人信息的事项无法监督和控制，由于第三方的原因导致您的个人信息遭受任何侵害的，您应向第三方主张权利，第四记对此不提供任何担保和保证，不承担任何法律责任。</p>
      <p class="p5">6、广告宣传</p>
      <p>您理解并同意，第四记有通过第四记服务的相关软件或网站向您关联或展示广告的权利，并对广告投放行为不承担通知义务，您不得以任何方式改变、移除或遮蔽这些广告，亦不得以任何形式利用第四记关联或展示任何广告。</p>
      <p>您理解并清楚，您对第四记产品或服务中关联或展示的广告应当审慎判断，您通过第四记产品或服务与广告商进行任何形式的通讯或商业往来，属于您与广告商之间的行为。如果您因前述任何行为而遭受的任何性质的后果或损失，您应向有关广告商主张权利。</p>
      <p class="h1">三、用户责任</p>
      <p class="pp1">1、本公司发现或收到他人举报或投诉用户违反本协议约定的，本公司有权不经通知随时对相关内容，包括但不限于用户资料、存储信息进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、功能封禁的处理，且通知用户处理结果。</p>
      <p class="pp1">2、因违反用户协议被封禁的用户，可以自行通过第四记客服查询封禁期限。未被永久封禁的账号在封禁期限届满后自助解封。本公司不承担用户因账号封禁而导致的任何损失。</p>
      <p class="pp1">3、用户保证不以任何形式侵犯第四记的权益，并使其免受损害，并承诺全面承担由此引发的全部法律责任。</p>
      <p class="pp1">4、用户理解并认同，用户因使用本产品或服务而违反有关法律法规，用户应承担由此而产生的一切法律责任.</p>
      <p class="pp1">5、用户理解并认同，因用户违反本协议约定，而导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户需全部承担并积极配合本公司或合作公司等消除影响。本公司保留追责的所有权利。本公司将尽可能的帮助用户避免第三方的索赔，但不视为义务。</p>
      <p class="pp1">6、用户可在使用本服务发布轨迹、图片及其他信息，但不得使用第四记服务发送或传播敏感信息和违反国家法律法规政策的信息，此类信息包括但不限于:</p>
      <p>①反对宪法所确定的基本原则的;</p>
      <p>②危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;</p>
      <p>③损害国家荣誉和利益的;</p>
      <p>④煽动民族仇恨、民族歧视，破坏民族团结的;</p>
      <p>⑤破坏国家宗教政策，宣扬邪教和封建迷信的;</p>
      <p>⑥含有法律、行政法规禁止的其他内容的。</p>
      <p
        class="pp1"
        style="marginTop:30px"
      >7、用户在使用本服务过程中，还必须遵循以下原则:</p>
      <p>①遵守中国有关的法律和法规;</p>
      <p>②用户不得侵任何人的知识产权，包括但不限于不得侵犯任何人的专利、商标、著作权、商业秘密或其他专属权利;</p>
      <p>③用户不得侵犯涉密信息，包括但不限于不得将无权传送的内容（例如商业秘密、内部资料、机密资料、个人信息等)进行上载或以其它方式发布、传送、传播、储存、提供;</p>
      <p>④用户不得为任何非法目的而使用网络服务系统，包括但不限于应当遵守法律法规，以及所有与网络服务有关的网络协议、规定和程序，不得利用第四记平台络服务系统进行任何可能对互联网的正常运转造成不利影响的行为，或其他任何非法行为;</p>
      <p>⑤用户不得对第四记上的任何数据作商业性利用，包括但不限于在未经第四记事先书面同意的情况下，以复制、发布、传播等任何方式使用第四记上展示的资料;</p>
      <p>⑥用户不得破坏本服务的提供，或侵犯本服务相关的任何权利，包括但不限于不得将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴或以其它方式传送;不得采取任何将导致不合理的庞大数据负载加诸第四记平台络设备的行动;</p>
      <p> ⑦　用户不得利用网络服务进行任何不利于第四记的行为。</p>
      <p class="h1">四、知识产权</p>
      <p class="pp1">1.第四记平台的一切知识产权（包括但不限于版权、商标权、专利权、商业秘密），以及相关的所有信息内容，包括但不限于：地图、线路轨迹、文字表述及其组合、数据信息、电子文件等均受法律保护；未经本公司书面同意，针对前述信息，用户不得为任何营利性或非营利性的目的自行实施、利用、转让、创造相关衍生作品或许可任何三方实施、利用、转让上述知识产权、创造相关衍生作品。</p>
      <p class="pp1">2. 第四记平台提供用户在线分享标签、轨迹、路线、照片等资源，为用户提供信息存储空间，鼓励热心用户充分利用本平台积极上传所累积的标签、轨迹、路线照片等资源与大家分享。个人应对发布内容的真实性与合法性负责，如果因个人发布的内容而产生的纠纷，第四记平台将不承担任何责任。即用户不应未经授权通过本软件使用其他组织或个人的受版权、商标权、专利权和其它财产所有权法律保护的内容。</p>
      <p class="pp1"> 3.本公司鼓励用户在“第四记平台”上传、粘贴自己的信息，但这些信息不得侵犯他人的知识产权，否则，由此引起的法律责任一概由用户自行承担，本公司若收到此类投诉，经过审核并确认后，将会删掉此等侵权信息。</p>
      <p class="pp1">4.第四记不对用户发表的内容及其它附属品（如行程、轨迹、活动、图片等）的正确性进行保证，用户发表的内容及其它附属品不代表第四记观点和立场。</p>
      <p class="h1">五、免责声明</p>
      <p class="pp1">1.用户理解并认同，“第四记平台” 存在因计算机病毒或黑客攻击等一切可预知与不可预知的外部原因而造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，本公司不承担任何责任。</p>
      <p class="pp1">2.用户理解并认同，本公司需要定期或不定期地对“第四记平台”或相关的设备进行检修或者维护，由此导致的产品无法使用，服务无法提供，本公司无需为此承担任何责任。本公司将尽可能在检修或者维护前通知用户，但并不视为义务。</p>
      <p class="pp1">3.用户理解并认同，本公司不能保证及时对第四记平台中违法违规或违约行为进行相应处理，本公司不承担由此所牵涉的任何责任。本公司将尽可能提前通知用户该违法、违规或违约行为，但并不视为义务。</p>
      <p class="pp1">4.在任何情况下，本公司均不承担任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“第四记平台”遭受的利润损失承担责任。</p>
      <p class="h1">六、法律管辖</p>
      <p class="pp1">1、本协议的订立、执行和解释及争议的解决均适用中国法律并受中国法院管辖。</p>
      <p class="pp1">2、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向本公司所在地的人民法院提起诉讼。</p>
      <p class="h1">七、服务的变更、中止和终止</p>
      <p>第四记有权自行选择发出或者不发出通知，以变更、中止或终止提供第四记产品或服务，也有权自行选择变更、中止或终止您对第四记产品的使用。如因服务器或系统的维护、升级需要或其他原因需要暂停或终止第四记产品或服务的全部（或者部分）内容，第四记将尽可能事先公告。如果百第四记永久终止提供第四记产品或服务，第四记将在服务终止前提前在相关软件或网站上公告，您同意第四记无需单独通知您。除法律法规明确要求百度承担责任之外，第四记无需因第四记产品或服务的任何变更、中止和终止向您或者任何第三方承担任何责任。</p>
      <p class="h1">八、期限及终止</p>
      <p class="pp1">1、本用户服务条款的生效日期应为您同意本用户服务条款之日或使用第四记产品或服务之日中的较早日期，并且无固定的终止日期，除非根据以下情形而终止：</p>
      <p>(1)您丧失民事权利能力或民事行为能力，或丧失其他主体的法律授权；</p>
      <p> (2)第四记因为任何原因终止向您提供第四记产品或服务；</p>
      <p>(3)您主动地不再使用第四记产品或服务； (4)其他导致本用户服务条款终止的原因。</p>
      <p class="pp1"> 2、本用户服务条款由于任何原因终止时，您将无法继续使用第四记产品或服务的任何部分，包括但不限于您的用户账号及用户内容。此外，您同意的相关单独附加条款也同时终止。</p>
      <p class="pp1">3、您理解并同意，本用户服务条款终止后，第四记将按照法律法规要求保存或删除您的个人信息，具体处理方式可参考《隐私政策》。</p>
      <p class="pp1">4、在本用户服务条款终止后，第四记仍然可以依据相关法律法规及本用户服务条款向您追究违反法律法规或违反本用户服务条款行为的责任，本用户服务条款中关于个人信息保护、知识产权、担保与保证、责任承担、免责及责任限制、法律适用和争议解决的条款始终有效。</p>
      <p class="h1">九、未成年用户特别提示</p>
      <p style="margin-bottom: 100px;">未成年用户必须遵守全国青少年网络文明公约。要善于网上学习，不浏览不良信息;要诚实友好交流，不侮辱欺诈他人;要增强自护意识，不随意约会网友;要维护网络安全，不破坏网络秩序;要有益身心健康，不沉溺虚拟时空。</p>

    </div>
    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
export default {
  components: { vHeader, vFooter },
  data() {
    return {
      inner: null,
    };
  },
  methods: {
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout();
    },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".zhence");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    this.scroll();
    this.hover();
    this.hoverout();
  },
};
</script>
<style scoped>
@import url("../../assets/css/zhence/zhence.css");
@import url("../../assets/css/xieyi/xieyi.css");
</style>
