import { render, staticRenderFns } from "./Huwaicaiji.vue?vue&type=template&id=4e3a5c04&scoped=true&"
import script from "./Huwaicaiji.vue?vue&type=script&lang=js&"
export * from "./Huwaicaiji.vue?vue&type=script&lang=js&"
import style0 from "./Huwaicaiji.vue?vue&type=style&index=0&id=4e3a5c04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e3a5c04",
  null
  
)

export default component.exports