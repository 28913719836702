<template>
  <div class="index">
    <!-- 头部 -->
    <v-header
      ref="header"
      :arr="arr"
      :he="he"
      :top="top"
    ></v-header>

    <div
      class="yidongapp"
      id="data-kong"
      style="margin:0 auto"
    >
      <div class="app_img">
        <img
          class="img"
          src="../../assets/img/组 1759.png"
          alt=""
        >
        <div class="mmm"><img
            class="img1"
            src="../../assets/img/组 1500.png"
            alt=""
          >
          <p class="text">
            提供丰富的资源及工具，帮您实现数据的多端同步查看和便捷管理，为您解决移动端困扰，是内业工作的好助手。
          </p>
          <span
            class="android"
            style="color:#0069E6"
            @click="kongjianpingtai"
          >

            开始使用
          </span>
        </div>

      </div>
      <ul style="margin:0 auto">
        <li
          class="li"
          id="guihuabiaohui"
          style="marginTop:136px;margin-bottom: 257px;"
        >
          <div class="left">
            <img
              src="../../assets/img/组 2040.png"
              alt=""
            >
          </div>
          <div class="right">
            <span style="width:100%;fontStyle:oblique;">01</span>
            <h3 style="textAlign:left;">多种图层资源</h3>
            <div style="width:100%">
              <p style="width:100%">支持天地图、高德系列常用地图</p>
              <p style="width:100%">在线专题图层资源及自定义底图、图源</p>
              <p style="width:100%">均与第四纪APP保持同步一致</p>
              <p style="width:100%">可根据工作需求切换底图、叠加图层</p>
              <p style="width:100%">有效辅助内业工作</p>
            </div>

          </div>
        </li>
        <li
          class="li"
          id="shishizhangkong"
        >
          <div class="right">
            <span style="width:100%;fontStyle:oblique;textAlign:right;">02</span>
            <h3 style="textAlign:right;">数据同步查看</h3>
            <div style="width:100%">
              <p style="width:100%;textAlign:right;">同一账号下第四记APP中同步的工程记录</p>
              <p style="width:100%;textAlign:right;">及备份的工作轨迹、标绘</p>
              <p style="width:100%;textAlign:right;">均可通过空间平台叠加查看、对比</p>
              <p style="width:100%;textAlign:right;">便于数据整理汇总</p>
            </div>

          </div>
          <div class="left">
            <img
              src="../../assets/img/组 2058.png"
              alt=""
            >
          </div>
        </li>
        <li
          class="li"
          id="shujutongbu"
          style="margin-top:367px;margin-bottom:248px"
        >
          <div class="left">
            <img
              src="../../assets/img/组 2070.png"
              alt=""
            >
          </div>
          <div class="right">
            <span style="width:100%;fontStyle:oblique;">03</span>
            <h3 style="textAlign:left;">多人协作</h3>
            <div>
              <p style="width:100%;">协作组成员在第四记APP中共享的记录内容及位置信息</p>
              <p style="width:100%">均可在空间平台同步查看</p>
              <p style="width:100%">便于及时准确的掌握协作内容</p>
              <p style="width:100%">保障外业任务高效率进行</p>
            </div>
          </div>

        </li>
        <li
          class="li"
          id="yunduanshuju"
        >

          <div class="right">
            <span style="width:100%;fontStyle:oblique;textAlign:right;">04</span>
            <h3 style="textAlign:right;">标记绘制</h3>
            <div style="width:100%">
              <p style="width:100%;textAlign:right;">集成丰富的标绘工具</p>
              <p style="width:100%;textAlign:right;"> 支持点、线、面要素绘制及个性化样式设置</p>
              <p style="width:100%;textAlign:right;">标绘保存后在第四记APP云端标绘中查看使用</p>
              <p style="width:100%;textAlign:right;"> APP中已备份的标绘在空间平台中可进行编辑、导出等操作</p>
              <p style="width:100%;textAlign:right;">支持导出.kml格式文件，便于分享及使用</p>
            </div>

          </div>
          <div class="left">
            <img
              src="../../assets/img/组 2066.png"
              alt=""
            >
          </div>
        </li>
        <li
          class="li"
          id="biaodanziyuan"
          style="margin-top:240px;margin-bottom: 244px;"
        >
          <div class="left">
            <img
              src="../../assets/img/组 3296.png"
              alt=""
            >
          </div>
          <div class="right">
            <span style="width:100%;fontStyle:oblique;">05</span>
            <h3 style="textAlign:left;">地图工具</h3>
            <div>
              <p style="width:100%;">提供地图专业测量、高程及磁偏角查询</p>
              <p style="width:100%">地理信息检索、界面快速截图、鹰眼图等常用工具</p>
            </div>
          </div>

        </li>
        <li
          class="li"
          id="duozhongtuceng"
          style="margin-bottom:240px"
        >

          <div class="right">
            <span style="width:100%;fontStyle:oblique;textAlign:right;">06</span>
            <h3 style="textAlign:right;">表单资源多样化</h3>
            <div style="width:100%">
              <p style="width:100%;textAlign:right;">提供不同行业标准的定制化表单</p>
              <p style="width:100%;textAlign:right;"> 支持创建多种字段类型的自定义表单</p>
              <p style="width:100%;textAlign:right;"> 表单保存后可在第四记APP云表单中查看使用</p>
              <p style="width:100%;textAlign:right;"> 便于用户规范、快速的展开记录</p>

            </div>

          </div>
          <div class="left">
            <img
              src="../../assets/img/组 2049.png"
              alt=""
            >
          </div>
        </li>
        <li
          class="li"
          id="chongyonggongju"
          style="marginBottom:193px"
        >
          <div class="left">
            <img
              style="width:638px;"
              src="../../assets/img/组 2050.png"
              alt=""
            >
          </div>
          <div
            class="right"
            style="transform:translateY(-30px)"
          >
            <span style="width:100%;fontStyle:oblique;">07</span>
            <h3 style="textAlign:left;">云空间管理</h3>
            <div>
              <p style="width:100%;">空间容量直观展示</p>
              <p style="width:100%">数据类别清晰明确内容排列有序</p>
              <p style="width:100%">高效管理云数据</p>

            </div>
          </div>

        </li>
        <div
          class="last_img"
          style="width: 100%;"
        >
          <img
            style="width:100%;z-index: -2000;"
            src="../../assets/img/蒙版组 59.png"
            alt=""
          >
        </div>
      </ul>
    </div>

    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import findid from "../../unitils/navrouter.js";
import { decide_router } from "../../unitils/decide_router";
export default {
  components: { vHeader, vFooter },
  data() {
    return {
      arr: [],
      top: [],
      he: null,
      innerwidth: "",
      inner: null,
    };
  },
  methods: {
    kongjianpingtai() {
      window.open("http://map.geoquater.com/#/login");
    },
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(1);
    },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-kong");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(1);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(1);
            }
          } else {
            return;
          }
        },
        true
      );
    }, // 二级导航样式
    // secondstyle() {
    //   this.$refs.header.two_style(3);
    // },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".yidongapp");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    this.getHeight();
    this.scroll();
    this.hover();
    this.hoverout();

    let that = this;
    decide_router(that);

    let li_img = document.querySelectorAll(".yidongapp ul li .left img");
    let li = document.querySelectorAll(".yidongapp ul li");
    let mmm = document.querySelector(".app_img .img");
    // mmm.onload = () => {
    //   this.he = mmm.clientHeight;
    // };

    setTimeout(() => {
      this.he = mmm.clientHeight;
      for (let j = 0; j < li_img.length; j++) {
        // li_img[j].onload = () => {
        this.arr.push(li_img[j].clientHeight);
        this.top.push(li[j].offsetTop);
        // };
      }
      findid(this.top, this.he);
    }, 200);
  },
};
</script>
<style scoped>
@import url("../../assets/css/yidongapp/yidongapp.css");
@import url("../../assets/css/kongjianpingtai/kongjianpingtai.css");
</style>
