<template>
  <div class="index">
    <!-- 头部 -->
    <v-header ref="header"></v-header>

    <div
      class="dataserve"
      id="data-jia"
      style="margin:0 auto"
    >
      <div class="title_img">
        <img
          src="../../assets/img/组 19391.png"
          alt=""
        >
        <div class="mmm">
          <h3>数据加工</h3>
          <p>基于行业标准，借助清晰、高效的数据加工流程，为您提供符合预期的处理成果，可帮助您对不同的数据有更深层次的利用。</p>
        </div>

      </div>
      <div class="jiagong">
        <ul class="jiagong_ul">
          <li>
            <h4>需求分析</h4>
            <p style="margin-bottom:61px">深入分析数据情况，确认数据量，明确客户数据加工目标与成果要求</p>
            <div class="jiagong_img">
              <img
                src="../../assets/img/蒙版组 37.png"
                alt=""
              >
            </div>

          </li>
          <li>
            <h4 style="margin-top: 180px;">数据加工</h4>
            <p style="width: 720px;">梳理现有数据，反馈缺项数据并跟进补充；对获取的数据严格按照国标行标、结合客户需求进行规范化处理</p>
            <div class="jiagong_img">
              <img
                src="../../assets/img/蒙版组 61.png"
                alt=""
              >
            </div>

          </li>
          <li style="margin-bottom:100px">
            <h4 style="margin-top: 180px;">成果提交</h4>
            <p style="width: 720px;margin-bottom: 52px;">提交前进行内部质量核验。提交时为您提供数据说明文档，对成果做系统的说明，便于客户理解和使用</p>
            <div class="jiagong_img">
              <img
                src="../../assets/img/资源 1.png"
                alt=""
              >
            </div>

          </li>
        </ul>
      </div>

    </div>
    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import { decide_router } from "../../unitils/decide_router";
export default {
  components: { vHeader, vFooter },
  data() {
    return { inner: null };
  },
  methods: {
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(2);
    },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-jia");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(2);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(2);
            }
          } else {
            return;
          }
        },
        true
      );
    },
    // 二级导航样式
    secondstyle() {
      let path = this.$route.path;
      console.log(path);
      this.$refs.header.three_style(0);
    },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".dataserve");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    this.secondstyle();
    let that = this;
    decide_router(that);
    this.scroll();
    this.hover();
    this.hoverout();
    this.getHeight();
  },
};
</script>
<style scoped>
@import url("../../assets/css/dataserve/dataserve.css");
@import url("../../assets/css/pingtaikaifa/pingtaikaifa.css");
@import url("../../assets/css/datajiagong/datajiagong.css");
</style>
