<template>
  <div class="index">
    <!-- 头部 -->
    <v-header
      ref="header"
      :arr="arr"
      :he="he"
      :top="top"
    ></v-header>

    <div
      class="dataserve"
      id="data-ser"
      style="margin:0 auto"
    >
      <div class="title_img">
        <img
          src="../../assets/img/蒙版组 34.png"
          alt=""
        >
        <div class="mmm">
          <h3>数据服务</h3>
          <p>提供数据采集、数据传输、数据存储、数据加工（包括计算、分析、可视化等）、数据制图等服务</p>
        </div>

      </div>
      <div class="first">
        <h3
          id="data-jiagong"
          style="margin-top:102px"
        >数据加工</h3>
        <p
          class="p"
          style="width:813px"
        >支持从大量的、杂乱无章的、难以理解的数据中抽取并推导出有价值、有意义的数据。通过建立数据库，可实现对数据系统地整理和存储，减少冗余</p>
        <ul style="margin-top :80px;">
          <li
            v-show="first"
            @mouseover="mouseover"
          >
            <img
              src="../../assets/img/组 1192.png"
              alt=""
            >
            <h4>数字化加工</h4>
            <p>DIGITA PROCESSING</p>
          </li>
          <li
            v-show="!first"
            id="one"
            @mouseout="out"
          >
            <div class="text">
              <h5>数字化加工</h5>
              <p>支持将栅格数据数字化为矢量数据，可完成不同要求、不同数据源的数字化工作。</p>
            </div>
          </li>
          <li
            v-show="second"
            @mouseover="mouseover_two"
          >
            <img
              src="../../assets/img/组 1316.png"
              alt=""
            >
            <h4>规范化加工</h4>
            <p>STANDARDIZED PROCESSING</p>
          </li>
          <li
            id="two"
            v-show="!second"
            @mouseout="out_two"
          >
            <div class="text">
              <h5>规范化加工</h5>
              <p>支持对矢量数据的规范化，提供修改空间拓扑问题，图幅接边问题，补充、规范矢量属性信息等服务；支持对栅格数据的规范化，提供拼接、分幅、渲染、影像金字塔创建等服务，实现栅格数据的显示优化，提供符合使用要求的栅格成果。</p>
            </div>

          </li>
          <li
            v-show="third"
            @mouseover="mouseover_three"
          >
            <img
              src="../../assets/img/组 1193.png"
              alt=""
            >
            <h4>数据入库</h4>
            <p>DATA STRONG</p>
          </li>
          <li
            id="three"
            v-show="!third"
            @mouseout="out_three"
          >
            <div class="text">
              <h5>数据入库</h5>
              <p>支持将加工处理的各类数据，按照既定的规则和标准录入数据库，实现对各类数据的高效管理。</p>
            </div>

          </li>
        </ul>
      </div>

      <div class="first">
        <h3
          id="data-shujuku"
          style="margin-top:130px;line-height: 37px"
        >数据库建设</h3>
        <p
          class="p p_text"
          style="width:792px"
        >提供从概念设计到数据入库的全流程服务，考虑数据特征与应用目的，兼顾库建设与用户使用两个方面，使用户获得逻辑合理、使用方便的数据库成果</p>
        <div class="data_jianshe">
          <div
            class="data_jianshe_img"
            style="margin-top:70px"
          >
            <img
              src="../../assets/img/组 1226.png"
              alt=""
            >
            <div class="left">
              <h4>数据库设计</h4>
              <p>提供数据库设计的多阶段服务。在概念设计阶段，全面了解产品设计的存储需求，数据处理需求，注重数据的安全性和完整性；在逻辑结构设计阶段，建设合理的数据实体之间的逻辑关系，兼顾空间数据和属性数据逻辑；在物理设计阶段，根据所使用的数据库特点进行表结构设计。</p>
            </div>
            <div class="right">
              <h4>质量管控</h4>
              <p>建设过程严格按照国标与行标相关要求开展，具有自检与互检机制，实现对数据正确性与完整性的质量控制。</p>
            </div>
            <div
              class="bottom"
              style="top:10%"
            >
              <h4>成果保障</h4>
              <p>提供数据库送检的全程跟进服务。在具有相关资质的第三方对数据库进行检测后，及时跟进，保障数据的合格性。</p>
            </div>
          </div>
        </div>

      </div>

      <div class="first">
        <h3 id="data-zhitu">数据制图</h3>
        <p
          id="p"
          style="width:603px;margin-top:30px;margin-bottom:91px"
        >支持对不同类型、来源的数据进行符号化，实现数据信息的清晰有效传达，使数据更加生动与形象</p>
        <div
          class="top"
          style="margin-bottom:90px"
        >
          <div class="left">
            <img
              src="../../assets/img/777.png"
              alt=""
            >
          </div>
          <div
            class="right"
            style="margin-right:72px"
          >
            <img
              src="../../assets/img/路径 1746.png"
              alt=""
            >
            <div class="text">
              <h4>质量管控</h4>
              <p>提供成果核验服务，包括要素层及其内容完整性，关联关系正确性，符号渲染正确性，注记位置合理性，布局美观程度等。严格按照国标行标的相关要求进行制图，确保成果质量。</p>
            </div>

          </div>
        </div>
        <div
          class="top"
          style="margin-bottom:100px"
        >
          <div
            class="right"
            style="margin-left:93px"
          >
            <img
              src="../../assets/img/路径 1746.png"
              class="right_img"
            >
            <div class="text">
              <h4 class="h4">成果保障</h4>
              <p class="p">提供成果图件专家审核服务，并全程跟进，修改专家审图意见，保障成果专业性与正确性。</p>
            </div>

          </div>
          <div class="left">
            <img
              src="../../assets/img/888.png"
              alt=""
            >
          </div>

        </div>

      </div>
      <div class="hengtiao"></div>
    </div>

    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import findid from "../../unitils/navrouter.js";
import { decide_router } from "../../unitils/decide_router";
export default {
  components: { vHeader, vFooter },
  data() {
    return {
      first: true,
      second: true,
      third: true,
      arr: [],
      top: [],
      he: null,
      inner: null,
    };
  },
  methods: {
    mouseover() {
      this.first = false;
    },
    mouseover_two() {
      this.second = false;
    },
    mouseover_three() {
      this.third = false;
    },
    out() {
      this.first = true;
    },
    out_two() {
      this.second = true;
    },
    out_three() {
      this.third = true;
    },
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(1);
    },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-ser");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(1);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(1);
            }
          } else {
            return;
          }
        },
        true
      );
    },
    // 二级导航样式
    // secondstyle() {
    //   this.$refs.header.two_style(0);
    // },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".dataserve");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    let that = this;
    decide_router(that);
    this.scroll();
    this.hover();
    this.hoverout();

    this.getHeight();

    let li = document.querySelectorAll(".dataserve .first");
    let img = document.querySelector(".dataserve .title_img");
    setTimeout(() => {
      this.he = img.clientHeight;
      for (let i = 0; i < li.length; i++) {
        this.top.push(li[i].offsetTop);
        this.arr.push(li[i].clientHeight);
      }
      findid(this.top, this.he);
    }, 50);
  },
};
</script>
<style scoped>
@import url("../../assets/css/dataserve/dataserve.css");
</style>
