<template>
  <div
    class="header"
    style="left: 50%;transform: translateX(-50%);"
  >
    <!-- logo图 -->
    <div
      class="logo"
      @click="geoquater"
    >
      <img
        src="../../assets/img/组 1015.png"
        alt=""
      >
    </div>
    <!-- 标题 -->
    <div class="nav">
      <ul class="nav_ul">
        <li><span
            @click="index"
            :class="active?'active':''"
          >首页</span></li>
        <li><span :class="act_serve?'active':''">产品业务</span></li>
        <li><span :class="act_jiagong?'active':''">解决方案</span></li>
        <li><span
            @click="guanyu"
            :class="act_guanyu?'active':''"
          >关于我们</span></li>
      </ul>
      <div
        class="mmmmm"
        v-show="two_picker"
      >
        <div
          class="two-picker"
          v-show="two_picker"
        >
          <div class="sanjiao">
            <img
              src="../../assets/img/三角形.png"
              alt=""
            >
          </div>
          <ul>
            <li style="margin-bottom:15px"><span
                class="first"
                @click="dataserve"
              >数据服务</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/dataserve','data-jiagong',0,$event)"
            ><span id="yyy">数据加工</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/dataserve','data-shujuku',1,$event)"
            ><span>数据库建设</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/dataserve','data-zhitu',2,$event)"
            ><span>数据制图</span></li>
          </ul>
          <ul>
            <li style="margin-bottom:15px">
              <span
                class="first"
                @click="pingtaikaifa"
              >平台开发服务</span>
            </li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/pingtaikaifa','ditujichu',0,$event)"
            ><span>地图基础</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/pingtaikaifa','shujukeshihua',1,$event)"
            ><span>数据可视化</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/pingtaikaifa','shujuguanli',2,$event)"
            ><span>数据管理</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/pingtaikaifa','shouquanguanli',3,$event)"
            ><span>授权管理</span></li>
          </ul>
          <ul>
            <li style="margin-bottom:15px"><span
                class="first"
                @click=yidongapp
              >第四记APP</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','tucengziyuan',0,$event)"
            ><span>多种图层资源</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','dingweidadian',1,$event)"
            ><span>便捷打点记录</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','shiyonggongju',2,$event)"
            ><span>实用外业工具</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','biaodanku',3,$event)"
            ><span>行业标准表单库</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','biaodanchuangjian',4,$event)"
            ><span>自定义表单创建</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','xietongzuoye',5,$event)"
            ><span>多人协作</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','shujutongbu',6,$event)"
            ><span>多设备数据同步</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','chuxingguiji',7,$event)"
            ><span>轨迹记录</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','biaojihuizhi',8,$event)"
            ><span>标记绘制</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','lixianditu',9,$event)"
            ><span>离线地图</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/yidongapp','daochuguanlian',10,$event)"
            ><span>导入/导出关联</span></li>
          </ul>
          <ul style="border:none">
            <li style="margin-bottom:15px"><span
                class="first"
                @click="kongjianpingtai"
              >第四记空间平台</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/kongjianpingtai','guihuabiaohui',0,$event)"
            ><span>多种图层资源&nbsp;</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/kongjianpingtai','shishizhangkong',1,$event)"
            ><span>数据同步查看</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/kongjianpingtai','shujutongbu',2,$event)"
            ><span>多人协作&nbsp;</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/kongjianpingtai','yunduanshuju',3,$event)"
            ><span>标记绘制&nbsp;</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/kongjianpingtai','biaodanziyuan',4,$event)"
            ><span>地图工具</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/kongjianpingtai','duozhongtuceng',5,$event)"
            ><span>表单资源多样化</span></li>
            <li
              style="margin-bottom:5px"
              @click="data_jiagong('/kongjianpingtai','chongyonggongju',6,$event)"
            ><span>云空间管理</span></li>
          </ul>
        </div>
      </div>
      <div class="nnnnn">
        <div
          class="three-picker"
          v-show="three_picker"
        >
          <div class="sanjiao2">
            <img
              src="../../assets/img/三角形.png"
              alt=""
            >
          </div>
          <ul>
            <li @click="datajiagong">
              <img
                :src="img[0]"
                alt=""
              >
              <p>数据加工</p>
            </li>
            <li @click="shujukujianshe">
              <img
                :src="img[1]"
                alt=""
              >
              <p>数据库建设</p>
            </li>
            <li @click="shujuzhitu">
              <img
                :src="img[2]"
                alt=""
              >
              <p>数据制图</p>
            </li>
            <li @click="jiejuepingtaikaifa">
              <img
                :src="img[3]"
                alt=""
              >
              <p>平台开发</p>
            </li>
            <li @click="huwaicaiji">
              <img
                :src="img[4]"
                alt=""
              >
              <p>户外数据采集</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="kongjian">
        <span @click="kongjianlogin">进入空间平台</span>
      </div>
      <div class="app">
        <span>下载APP</span>
      </div>
      <div
        class="erweima"
        v-show="erweima"
      >
        <img
          style="transform:translateY(-13px)"
          src="../../assets/img/联合 1.png"
          alt=""
        >
        <img
          style="width:116px"
          class="saoma"
          src="../../assets/img/download-app.png"
          alt=""
        >
      </div>
    </div>

  </div>
</template>
<script>
import log1 from "../../assets/img/组 1015.png";
import log2 from "../../assets/img/组 1925.png";
import img1 from "../../assets/img/组 1001.png";
import img2 from "../../assets/img/组 1004.png";
import img3 from "../../assets/img/组 1006.png";
import img4 from "../../assets/img/组 1007.png";
import img5 from "../../assets/img/组 1008.png";
import img11 from "../../assets/img/组 1001_1.png";
import img22 from "../../assets/img/组 1004_1.png";
import img33 from "../../assets/img/组 1006_1.png";
import img44 from "../../assets/img/组 1007_1.png";
import img55 from "../../assets/img/组 1008_1.png";
export default {
  props: {
    arr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    he: {
      type: Number,
      default: () => {
        return null;
      },
    },
    top: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      scroll: "",
      two_picker: false,
      three_picker: false,
      img: [img1, img5, img2, img3, img4],
      img_two: [img11, img55, img22, img33, img44],
      erweima: false,
      mmm: false,
      active: false,
      act_guanyu: false,
      act_serve: false,
      act_jiagong: false,
      inner: null,
    };
  },
  methods: {
    // 二级定位跳转
    // 数据服务
    // 平台开发服务
    // 第四记app
    // 第四记空间平台
    // 导航栏跳转
    data_jiagong(path, id, num, e) {
      let span = document.querySelectorAll(".two-picker ul li span");
      let ele = e.path[0].innerText;
      console.log(e);
      for (let i = 0; i < span.length; i++) {
        if (span[i].innerText == ele) {
          span[i].style.color = "#0069E6";
          span[i].style.borderBottom = "1px solid #0069E6";
        } else {
          span[i].style.color = "#777";
          span[i].style.borderBottom = "none";
        }
      }
      if (this.$route.path == path) {
        if (
          this.$route.path == "/dataserve" ||
          this.$route.path == "/pingtaikaifa"
        ) {
          window.scrollTo({
            top: this.top[num] - 120,
            left: 0,
            behavior: "smooth", //重点在此
          });
        } else {
          window.scrollTo({
            top: this.top[num] + this.he - 50,
            left: 0,
            behavior: "smooth", //重点在此
          });
        }
      } else {
        localStorage.setItem("id", id);
        localStorage.setItem("num", num);
        localStorage.setItem("path", path);
        this.$router.push(path);
      }
    },

    kongjianpingtai() {
      this.$router.push("/kongjianpingtai");
      location.reload();
    },
    yidongapp() {
      this.$router.push("/yidongapp");
      window.location.reload(true);
    },
    kongjianlogin() {
      window.open("http://map.geoquater.com/#/login");
    },
    guanyu() {
      this.$router.push("/guanyu");
      location.reload();
    },

    huwaicaiji() {
      this.$router.push("/huwaicaiji");
      location.reload();
    },
    jiejuepingtaikaifa() {
      this.$router.push("/jiejuepingtaikaifa");
      location.reload();
    },
    shujuzhitu() {
      this.$router.push("/shujuzhitu");
      location.reload();
    },
    shujukujianshe() {
      this.$router.push("/shujukujianshe");
      location.reload();
    },
    datajiagong() {
      this.$router.push("/datajiagong");
      location.reload();
    },
    dataserve() {
      this.$router.push("/dataserve");
      location.reload();
    },
    pingtaikaifa() {
      this.$router.push("/pingtaikaifa");
      location.reload();
    },
    index() {
      this.$router.push("/");
      location.reload();
    },
    dataserve() {
      this.$router.push("/dataserve");
      location.reload();
    },
    // 鼠标滚动
    handleScroll() {
      // 监听鼠标滚动
      // 页面滚动距顶部距离
      var header = document.querySelector(".header");
      var img = document.querySelector(".header .logo img");
      var vli = document.querySelectorAll(".nav ul li span");
      var scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scroll > 0) {
        header.style.backgroundColor = "#fff";
        header.style.color = "#000";
        img.src = log2;
        header.style.boxShadow = "0 0 15px 5px rgba(0, 0, 0, 0.2)";
      } else if (scroll == 0) {
        header.style.background = "";
        img.src = log1;
        header.style.color = "#fff";
        header.style.boxShadow = "";
      }
      this.scroll = scroll;
    },
    // 点击logo跳转官网
    geoquater() {
      window.open("/");
    },
    // 鼠标滑过底部横条
    mouseover() {
      // nav 鼠标滑过样式
      var vli = document.querySelectorAll(".nav .nav_ul li span");
      // console.log(vli);
      var header = document.querySelector(".header");
      var nn = window.getComputedStyle(header);

      for (let i = 0; i < vli.length; i++) {
        vli[i].onmouseover = () => {
          if (nn.backgroundColor == "rgba(0, 0, 0, 0)") {
            vli[i].style.borderBottom = "2px solid #fff";
          } else {
            vli[i].style.borderBottom = "2px solid #0069E6";
            vli[i].style.color = "#0069e6";
          }

          vli[i].style.fontSize = "22px";
          vli[i].style.cursor = "pointer";
          if (i == 1) {
            this.two_picker = true;
            this.three_picker = false;
          }
          if (i == 2) {
            this.three_picker = true;
            this.two_picker = false;
          }
          if (i == 0 || i == 3) {
            this.three_picker = false;
            this.two_picker = false;
          }
        };
      }
      let two_div = document.querySelector(".header .two-picker");
      two_div.onmouseover = () => {
        this.mmm = true;
        this.two_picker = true;
      };
      let three_div = document.querySelector(".header .three-picker");
      three_div.onmouseover = () => {
        this.mmm = true;
        this.three_picker = true;
      };
      //  下载鼠标滑过样式
      var odiv = document.querySelectorAll(".header .right div span");
      for (let i = 0; i < odiv.length; i++) {
        odiv[i].onmouseover = () => {
          if (nn.backgroundColor == "rgba(0, 0, 0, 0)") {
            odiv[i].style.borderBottom = "2px solid #fff";
          } else {
            odiv[i].style.borderBottom = "2px solid #0069E6";
            odiv[i].style.color = "#0069e6";
          }
          odiv[i].style.fontSize = "22px";
          odiv[i].style.cursor = "pointer";
          if (i == 1) {
            this.erweima = true;
          }
          if (i == 0) {
            this.erweima = false;
          }
        };
      }
      let xiazai_div = document.querySelector(".header .erweima");
      xiazai_div.onmouseover = () => {
        this.erweima = true;
        this.mmm = true;
      };
      //  产品业务--二级菜单鼠标滑过样式
      // var mli = document.querySelectorAll(".header .two-picker li span");
      // for (let i = 0; i < mli.length; i++) {
      //   mli[i].onmouseover = () => {
      //     mli[i].style.color = "#0069E6 ";
      //     mli[i].style.borderBottom = "1px solid #0069E6";
      //   };
      // }
      //  解决方案--二级菜单鼠标滑过样式
      var jjfali = document.querySelectorAll(".header .three-picker li");
      var jjfaimg = document.querySelectorAll(".header .three-picker li img");
      var jjfap = document.querySelectorAll(".header .three-picker li p");
      for (let i = 0; i < jjfaimg.length; i++) {
        jjfali[i].onmouseover = () => {
          jjfaimg[i].src = this.img_two[i];
          jjfap[i].style.color = "#0069E6";
        };
      }
    },
    mouseout(num) {
      // nav鼠标移出样式
      let vli = document.querySelectorAll(".nav .nav_ul li span");
      for (let i = 0; i < vli.length; i++) {
        vli[i].onmouseout = () => {
          if (i == num) {
            // vli[i].style.fontSize = "18px";
            vli[i].style.color = "";
            vli[i].style.borderBottom = "";
          } else {
            vli[i].style.borderBottom = "";
            vli[i].style.fontSize = "18px";
            vli[i].style.color = "";
          }

          if (i == 1) {
            if (this.mmm == true) {
              this.two_picker = true;
              this.mmm = false;
            } else {
              this.two_picker = false;
              this.mmm = true;
            }
          }
          if (i == 2) {
            if (this.mmm == true) {
              this.three_picker = true;
              this.mmm = false;
            } else {
              this.three_picker = false;
              this.mmm = true;
            }
          }
        };
      }
      let two_div = document.querySelector(".header .two-picker");
      two_div.onmouseout = () => {
        this.two_picker = false;
        this.mmm = false;
      };

      let three_div = document.querySelector(".header .three-picker");
      three_div.onmouseout = () => {
        this.three_picker = false;
        this.mmm = false;
      };

      //   下载鼠标移出
      let odiv = document.querySelectorAll(".header .right div span");
      for (let i = 0; i < odiv.length; i++) {
        odiv[i].onmouseout = () => {
          odiv[i].style.borderBottom = "";
          odiv[i].style.fontSize = "18px";
          odiv[i].style.color = "";
          if (i == 1) {
            if (this.mmm == true) {
              this.erweima = true;
              this.mmm = false;
            } else {
              this.erweima = false;
              this.mmm = true;
            }
          }
        };
      }
      let xiazai_div = document.querySelector(".header .erweima");
      xiazai_div.onmouseout = () => {
        this.erweima = false;
        this.mmm = false;
      };
      //  产品业务--二级菜单鼠标滑过样式
      // var mli = document.querySelectorAll(".header .two-picker li span");
      // for (let i = 0; i < mli.length; i++) {
      //   mli[i].onmouseout = () => {
      //     mli[i].style.color = "";
      //     mli[i].style.borderBottom = "";
      //   };
      // }
      //  解决方案--二级菜单鼠标滑过样式
      var jjfali = document.querySelectorAll(".header .three-picker li");
      var jjfaimg = document.querySelectorAll(".header .three-picker li img");
      var jjfap = document.querySelectorAll(".header .three-picker li p");
      for (let i = 0; i < jjfaimg.length; i++) {
        jjfali[i].onmouseout = () => {
          jjfaimg[i].src = this.img[i];
          jjfap[i].style.color = "";
        };
      }
    },
    // 页面滚动，标题样式
    navstyle(num) {
      let span = document.querySelectorAll(".nav ul li span")[num];
      span.style.color = "#0069E6";
      span.style.borderBottom = "2px solid #0069E6";
      span.style.fontSize = "22px";
      span.onmouseout = () => {
        span.style.color = "#0069E6";
        span.style.borderBottom = "2px solid #0069E6";
        span.style.fontSize = "22px";
      };
    },
    // 页面置顶
    topstyle(num) {
      let span = document.querySelectorAll(".nav ul li span")[num];
      span.style.color = "white";
      span.style.borderBottom = "2px solid #fff";
      span.style.fontSize = "22px";
      span.onmouseout = () => {
        span.style.color = "white";
        span.style.borderBottom = "2px solid #fff";
        span.style.fontSize = "22px";
      };
    },
    // 弹框三二级导航选中样式
    three_style(num) {
      var jjfaimg = document.querySelectorAll(".header .three-picker li img");
      var jjfap = document.querySelectorAll(".header .three-picker li p");
      // console.log(jjfap);
      jjfaimg[num].src = this.img_two[num];
      jjfap[num].style.color = "#0069E6";
    },
    // 弹框二二级导航选中样式
    // two_style(num) {
    //   let span = document.querySelectorAll(".two-picker ul li .first");
    //   span[num].style.color = "#0069e6";
    //   span[num].style.borderBottom = "1px solid #0069e6";
    // },
  },

  mounted() {},
};
</script>
<style scoped>
@import url("../../assets/css/index/index.css");
@import url("../../assets/css/css_public/css_public.css");
</style>