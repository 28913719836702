import { render, staticRenderFns } from "./Yidongapp.vue?vue&type=template&id=fbc94904&scoped=true&"
import script from "./Yidongapp.vue?vue&type=script&lang=js&"
export * from "./Yidongapp.vue?vue&type=script&lang=js&"
import style0 from "./Yidongapp.vue?vue&type=style&index=0&id=fbc94904&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc94904",
  null
  
)

export default component.exports