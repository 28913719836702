import { render, staticRenderFns } from "./Shujukujianshe.vue?vue&type=template&id=50e6adee&scoped=true&"
import script from "./Shujukujianshe.vue?vue&type=script&lang=js&"
export * from "./Shujukujianshe.vue?vue&type=script&lang=js&"
import style0 from "./Shujukujianshe.vue?vue&type=style&index=0&id=50e6adee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e6adee",
  null
  
)

export default component.exports