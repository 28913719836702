<template>
  <div class="index">
    <!-- 头部 -->
    <v-header
      ref="header"
      id="data-kai"
    ></v-header>

    <div
      class="dataserve"
      id="data-jie"
      style="margin:0 auto"
    >
      <div class="title_img">
        <img
          src="../../assets/img/组 1138.png"
          alt=""
        >
        <div class="mmm">
          <h3>平台开发</h3>
          <p>聚焦空间数据特征，借助地理信息工具，将工程/科研成果进行可视化，汇集专业软件成果，从而更加便捷、直观的呈现多源数据的分布与状态，并根据需要进行数据的管理和授权</p>
        </div>

      </div>
      <div class="pingtai_first">
        <ul style="margin-top:60px">
          <li style="margin-left:61px">
            <img
              src="../../assets/img/组 1136.png"
              alt=""
            >
            <h4>工程/科研成果展示平台</h4>
            <p>
              深入分析工程/科研成果特征，结合数据时空特性，针对数据多时期、多手段、多来源、多地区、多模态的诸多特点，建设用于决策规划和评价应用的支撑平台。
            </p>
          </li>
          <li style="height:468px">
            <img
              src="../../assets/img/组 1135.png"
              alt=""
            >
            <h4>数据管理平台</h4>
            <p>
              依托空间数据库的建立，实现数据空间信息与属性信息的管理互动。为您提供图层发布、数据管理、空间分析、统计分选等多维度的数据管理应用。
            </p>
          </li>
          <li style="margin-right:59px">
            <img
              src="../../assets/img/组 1136.png"
              alt=""
            >
            <h4>数据共享授权平台</h4>
            <p>
              数据浏览和使用的权限与用户角色的分配和授权，在成果管理及应用中，需要得到准确合理的设计，以便在发挥数据共享效益的同时，有效管控权限内容的供给。
            </p>
          </li>
        </ul>
      </div>

      <!-- <div class="pingtai_second">
        <h4 style="margin-top:142px;margin-bottom:30px">典型案例</h4>
        <p
          class="p"
          style="width:460px"
        >各级管理及研究单位数据可视化管理平台项目级数据可视化应用平台</p>
        <div class="pingtai_second_img">
          <img
            src="../../assets/img/组 1137.png"
            alt=""
          >
        </div>
        <div
          class="lll"
          style="width: 100%;background-color: #E5F0FD;height: 544px;position: relative;padding-top: 6px;"
        >
          <div class="pingtai_middle">
            <div
              class="left"
              style="width:415px;position:absolute;top: 168px;left: 50%;transform: translateX(-145%);"
            >
              <h5 class="title">地震活动断层探察数据管理平台</h5>
              <p>
                依托全国地震活动断层探查数据，融合建设面向对
                象技术、组件技术、 GIS 及 WebGIS 等技术的数
                据管理平台，提供地震活动断层探察空间数据可视
                化展示和多层次查询、授权等功能。
              </p>
              <h5
                class="bottom"
                @click="hdcdata"
              >
                <span>平台样例</span>
                <div class="bottom_img">
                  <img
                    src="../../assets/img/图层 2.png"
                    alt=""
                  >
                  <img
                    src="../../assets/img/图层 2.png"
                    alt=""
                  >
                </div>

              </h5>
            </div>
            <div
              class="right"
              style="transform:translateX(265px)"
            >
              <img
                src="../../assets/img/蒙版组 19.png"
                alt=""
              >
            </div>
          </div>
        </div>

        <div
          class="pingtai_middle"
          id="pingtai_middle"
          style="margin-bottom:30px"
        >
          <div class="right">
            <img
              src="../../assets/img/蒙版组 18.png"
              alt=""
            >
          </div>
          <div
            class="left"
            style="width:396px;margin-right: 39px;height: 500px;"
          >
            <h5
              class="title"
              style="margin-top:168px"
            >兰州榆中生态创新城建设项目</h5>
            <p>
              建设集成果存储、查询、管理、分析和编辑的空
              间数据可视化平台。支持主要断层勘查和活动性
              鉴定、基岩地震动参数、地震危险性分析、地震
              地质灾害和地表地震动参数等各类原始探测记录
              及综合分析结果的展示查询和计算应用。
            </p>
            <h5
              class="bottom"
              @click="quping"
            >
              <span>平台样例</span>
              <div class="bottom_img">
                <img
                  src="../../assets/img/图层 2.png"
                  alt=""
                >
                <img
                  src="../../assets/img/图层 2.png"
                  alt=""
                >
              </div>

            </h5>
          </div>

        </div>
      </div> -->

    </div>

    <!-- 底部 -->
    <v-footer class="footer" style="margin-top:100px"></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import { decide_router } from "../../unitils/decide_router";
export default {
  components: { vHeader, vFooter },
  data() {
    return { inner: null };
  },
  methods: {
    // 跳转曲屏
    quping() {
      window.open("http://quping.geoquater.com/");
    },
    // 跳转活断层平台
    hdcdata() {
      window.open("http://data.geoquater.com/login");
    },
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(2);
    },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-jie");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(2);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(2);
            }
          } else {
            return;
          }
        },
        true
      );
    },
    // 二级导航样式
    secondstyle() {
      this.$refs.header.three_style(3);
    },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".dataserve");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    this.scroll();
    this.hover();
    this.hoverout();
    this.getHeight();
    let that = this;
    decide_router(that);
    this.secondstyle();
  },
};
</script>
<style scoped>
@import url("../../assets/css/dataserve/dataserve.css");
@import url("../../assets/css/pingtaikaifa/pingtaikaifa.css");
@import url("../../assets/css/datajiagong/datajiagong.css");
@import url("../../assets/css/jiejuepingtaikaifa/jiejuepingtaikaifa.css");
</style>
