<template>
  <div class="index">
    <!-- 头部 -->
    <v-header
      ref="header"
      :arr="arr"
      :he="he"
      :top="top"
    ></v-header>

    <div
      class="dataserve"
      id="data-fa"
      style="margin:0 auto"
    >
      <div class="title_img">
        <img
          src="../../assets/img/平台开发服务.png"
          alt=""
        >
        <div class="mmm">
          <h3>平台开发服务</h3>
          <p>实现空间数据可视化展示、海量数据检索、空间数据与多种类别数据相关联，提供便捷的空间分析与快速制图等功能。</p>
        </div>

      </div>
      <div class="first mm">
        <h3
          id="ditujichu"
          style="margin-top:104px"
        >地图基础</h3>
        <p
          class="p"
          style="height:56px;margin-bottom:42px"
        >包含地图基本操作、图面空间测量及地图要素标记等基本地图交互功能。</p>
        <div
          class="ptkf_map"
          style="margin-bottom:48px"
        >
          <div class="left">
            <img
              src="../../assets/img/组 1063.png"
              alt=""
            >
          </div>
          <div
            class="right"
            style="margin-right: 164px;"
          >
            <h4 id="ditucaozuo">地图操作</h4>
            <p>具有地图的平移、放大、缩小，鹰眼图、比例尺及实时坐标信息，多底图切换等地图窗口基本操作功能。</p>
          </div>
        </div>
        <div
          class="ptkf_map"
          style="margin-bottom:102px"
        >
          <div
            class="right"
            style="margin-left:186px"
          >
            <h4 class="right_two">测量/标记工具</h4>
            <p>提供地图界面内距离测量、面积测算，空间点、线、面的标记绘制及属性添加和修改控制。</p>
          </div>
          <div class="left">
            <img
              src="../../assets/img/组 1469.png"
              alt=""
            >
          </div>

        </div>
      </div>

      <div
        class="sjksh mm "
        style="height: 720px;"
      >
        <div
          class="inner"
          style="height: 720px; display: flex; flex-direction: column;justify-content: center; align-items: center;"
        >
          <h3
            id="shujukeshihua"
            style="margin-top: 120px;"
          >数据可视化</h3>
          <p
            class="p"
            style="height: 62px;margin-top: 30px;"
          >包含图层创建、发布、控制及设置等多维度图层可视化管理操作。</p>
          <div
            class="sj_img"
            style="margin-bottom: 161px;margin-top: 80px;"
          >
            <p class="left">
              提供开源图层的接入和用户专业图层的制作，定制图层目录并渲染发布专题服务。
            </p>
            <img
              src="../../assets/img/组 1090.png"
              alt=""
            >
            <p class="right">
              支持图层加载、定位、置顶置底，图例展示及样式、效果的修改。
            </p>
          </div>
        </div>

      </div>

      <div
        class="first mm"
        id="sjgl"
      >
        <h3
          id="shujuguanli"
          style="margin-top: 105px;"
        >数据管理</h3>
        <p
          id="p"
          style="height: 62px;margin-top: 30px;margin-bottom: 90px;"
        >满足数据的查询、统计、分析和增删改等管理需求</p>
        <ol>
          <li>
            <img
              src="../../assets/img/组 1093.png"
              alt=""
            >
            <div class="wenzi">
              <h5>数据查询</h5>
              <p>支持数据的空间查询和属性检索，可通过绘制兴趣区查询数据分布及记录详情，便于快速定位目标数据。</p>
            </div>

          </li>
          <li>
            <img
              src="../../assets/img/组 1098.png"
              alt=""
            >
            <div class="wenzi">
              <h5>数据统计</h5>
              <p>提供可视化的数据统计服务，快速掌控全局数据的分布、比例、趋势及动态信息，辅助用户决策管理。</p>
            </div>

          </li>
          <li>
            <img
              src="../../assets/img/组 1099.png"
              alt=""
            >
            <div class="wenzi">
              <h5>数据分析</h5>
              <p>提供常用数据分析模块，并可为用户提供专用分析模块开发。</p>
            </div>

          </li>
        </ol>
      </div>
      <div
        class="first mm"
        id="last"
        style="marginTop:310px"
      >
        <h3 id="shouquanguanli">授权管理</h3>
        <p
          id="p"
          style="height: 62px;width: 577px;margin-top: 30px;margin-bottom: 120px;"
        >支持多身份授权的定制化解决方案设计，提供便捷的可视化工作管理前台，满足收放自如的一手掌控。</p>
      </div>
    </div>
    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import findid from "../../unitils/navrouter.js";
import { decide_router } from "../../unitils/decide_router";
export default {
  components: { vHeader, vFooter },
  data() {
    return {
      top: [],
      arr: [],
      he: null,
      inner: null,
    };
  },
  methods: {
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(1);
    },
    // getHeight() {
    //   let h = document.getElementById("ditujichu").offsetTop;
    //   console.log(h);
    // },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-fa");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(1);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(1);
            }
          } else {
            return;
          }
        },
        true
      );
    },
    // 二级导航样式
    // secondstyle() {
    //   this.$refs.header.two_style(1);
    // },
  },
  mounted() {
    let ht = document.querySelector("html");
    ht.style.overflowX = "hidden";
    let inner = document.body.clientWidth;
    let head = document.querySelector(".header");
    let foot = document.querySelector(".footer");
    let ban = document.querySelector(".dataserve");

    foot.style.width = inner + "px";
    head.style.width = inner + "px";
    ban.style.width = inner + "px";
    window.addEventListener("resize", function () {
      let inner = document.body.clientWidth;
      if (inner > window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "auto";
      } else if (inner < window.screen.width) {
        let ht = document.querySelector("html");
        ht.style.overflowX = "hidden";
      }
    });
    this.scroll();
    this.hover();
    this.hoverout();

    this.getHeight();
    let that = this;
    decide_router(that);
    // this.secondstyle();

    let li = document.querySelectorAll(".dataserve .mm h3");
    let img = document.querySelector(".title_img img");
    img.onload = () => {
      this.he = img.clientHeight;
    };
    setTimeout(() => {
      for (let n = 0; n < li.length; n++) {
        this.top.push(li[n].offsetTop);
        this.arr.push(li[n].clientHeight);
      }
      findid(this.top, this.he);
    }, 50);
  },
};
</script>
<style scoped>
@import url("../../assets/css/dataserve/dataserve.css");
@import url("../../assets/css/pingtaikaifa/pingtaikaifa.css");
</style>
