<template>
  <div class="index">
    <!-- 头部 -->
    <v-header
      ref="header"
      :arr="arr"
      :he="he"
      :top="top"
    ></v-header>

    <div
      class="yidongapp"
      id="data-app"
      style="margin:0 auto"
    >
      <div
        class="app_img"
        style="margin: 0 auto;"
      >
        <img
          class="img"
          src="../../assets/img/组 1758.png"
          alt=""
        >
        <div class="mmm">
          <img
            class="img1"
            src="../../assets/img/图层 2_1.png"
            alt=""
          >
          <p class="text">
            一款关注外业工作过程中数据产出效率与质量的移动应用，是户外人员的记录伙伴。
          </p>

          <span
            class="android"
            @click="anddow"
          >
            <img
              src="../../assets/img/组 1482.png"
              alt=""
            >
            <i style="color:#0069E6">Android 下载</i>
          </span>
          <span
            class="apple"
            @click="iosdow"
          >
            <img
              src="../../assets/img/组 1481.png"
              alt=""
            >
            <i style="color:#0069E6">IOS 下载</i>
          </span>

        </div>
      </div>
      <ul style="margin:0 auto">
        <li
          class="li"
          id="tucengziyuan"
          style="margin-top:102px"
        >
          <div class="left">
            <img
              src="../../assets/img/组 2007.png"
              alt=""
            >
          </div>
          <div class="right">
            <span style="font-style:italic">01</span>
            <h3 style="text-align:left">多种图层资源</h3>
            <div>
              <p>支持天地图、高德系列常用地图</p>
              <p>接入NGAC、CEDPC、IGL、CEIC等开放的专题图层</p>
              <p>可自定义添加底图、图源</p>
              <p>根据需要切换底图、叠加数据，有效辅助外业工作</p>
            </div>

          </div>
        </li>
        <li
          class="li"
          id="dingweidadian"
          style="margin-bottom:140px"
        >
          <div class="right">
            <span style="text-align:right;font-style:italic">02</span>
            <h3 style="text-align:right">便捷打点记录</h3>
            <div style="display:flex;flex-direction:column;align-items:right;">
              <p style="text-align:right;margin:0;width:100%">支持点、线、面记录</p>
              <p style="text-align:right;margin:0;width:100%">通过输入坐标值或地图选点方式快速定位目标点</p>
              <p style="text-align:right;margin:0;width:100%">开展表单记录或跳转第三方导航应用前往目标位置</p>
              <p style="text-align:right;margin:0;width:100%">使定点导航更加便捷准确</p>
            </div>
          </div>
          <div class="left">
            <img
              src="../../assets/img/组 3295.png"
              alt=""
            >
          </div>
        </li>
        <li
          class="li"
          id="shiyonggongju"
          style="width:100%;height: 468px; background-color: #E5F0FD;margin-bottom: 140px;"
        >
          <div class="inner">
            <div
              class="left"
              style="margin-left:247px"
            >
              <img
                style="width:314px"
                src="../../assets/img/组 1321.png"
                alt=""
              >
            </div>
            <div
              class="right"
              style="width:432px;margin-right:53px"
            >
              <span style="font-style:italic">03</span>
              <h3 style="text-align:left;">实用外业工具</h3>
              <div>
                <p>支持地图界面距离、面积测算，高程、磁偏角查询</p>
                <p>提供罗盘、水平仪、水印相机等外业常用工具</p>
                <p>其中罗盘工具及高程、磁偏角查询</p>
                <p>支持表单内特殊字段自动获取</p>
              </div>

            </div>
          </div>

        </li>
        <li
          class="li"
          id="biaodanku"
        >

          <div class="right">
            <span style="text-align:right;font-style:italic">04</span>
            <h3 style="text-align:right">行业标准表单库</h3>
            <div>
              <p style="width:100%;text-align:right">针对不同行业领域的工作需求</p>
              <p style="width:100%;text-align:right">根据相关最新标准</p>
              <p style="width:100%;text-align:right">提供相应的定制化表单</p>
              <p style="width:100%;text-align:right">便于规范、快速的展开记录</p>
            </div>
          </div>
          <div class="left">
            <img
              src="../../assets/img/组 1990.png"
              alt=""
            >
          </div>
        </li>
        <li
          class="li"
          id="biaodanchuangjian"
        >
          <div class="left">
            <img
              src="../../assets/img/组 2011.png"
              alt=""
            >
          </div>
          <div class="right">
            <span style="font-style:italic">05</span>
            <h3 style="text-align:left">自定义表单创建</h3>
            <div>
              <p>支持自定义表单创建</p>
              <p>根据记录需要添加自定义记录字段</p>
              <p>多种字段类型可随意搭配组合</p>
              <p>支持对单一字段进行必填、添加显示条件等设置</p>
              <p>满足各种业务属性</p>
            </div>

          </div>
        </li>
        <li
          class="li"
          id="xietongzuoye"
          style="margin-bottom:140px"
        >

          <div class="right">
            <span style="text-align:right;font-style:italic">06</span>
            <h3 style="text-align:right">多人协作</h3>
            <div>
              <p style="width:100%;text-align:right">团队协作，记录共享</p>
              <p style="width:100%;text-align:right">支持记录数据定时或手动同步</p>
              <p style="width:100%;text-align:right">随时掌握组内成员位置信息及记录情况</p>
              <p style="width:100%;text-align:right">及时准确的调控协作</p>
              <p style="width:100%;text-align:right">协助团队汇总成果</p>
            </div>

          </div>
          <div class="left">
            <img
              src="../../assets/img/组 1977_1.png"
              alt=""
            >
          </div>
        </li>

        <li
          id="shujutongbu"
          style="width:100%;height: 520px; background-color: #E5F0FD;margin-bottom: 140px;"
        >
          <div class="inner">
            <div
              class="left"
              style="margin-left: 228px;"
            >
              <img
                style="height:320px"
                src="../../assets/img/组 2010.png"
                alt=""
              >
            </div>
            <div
              class="right"
              style="width:413px;margin-right:72px"
            >
              <span style="font-style:italic">07</span>
              <h3 style="text-align:left">多设备数据同步</h3>
              <div>
                <p>提供工程记录同步，标绘、轨迹、表单备份</p>
                <p>在同一账号下云空间内数据</p>
                <p>可使用第四记空间平台或其他移动设备进行查看</p>
                <p>便于数据存储管理，防止数据意外丢失</p>

              </div>

            </div>
          </div>

        </li>
        <li
          class="li"
          id="chuxingguiji"
        >

          <div class="right">
            <span style="text-align:right;font-style:italic">08</span>
            <h3 style="text-align:right">轨迹记录</h3>
            <div>
              <p style="text-align:right;width: 100%;">方便您在外业工作中随时记录出行轨迹</p>
              <p style="text-align:right;width: 100%;">支持对轨迹线条宽度、色彩、色彩透明度的设置</p>
              <p style="text-align:right;width: 100%;">提供已记录轨迹相关信息查看及轨迹数据导出.kml功能</p>

            </div>
          </div>
          <div class="left">
            <img
              src="../../assets/img/组 2012.png"
              alt=""
            >
          </div>
        </li>
        <li
          class="li"
          id="biaojihuizhi"
        >
          <div class="left">
            <img
              src="../../assets/img/组 2014.png"
              alt=""
            >
          </div>
          <div class="right">
            <span style="font-style:italic">09</span>
            <h3 style="text-align:left">标记绘制</h3>
            <div>
              <p>提供点、线、面要素绘制及个性化样式设置</p>
              <p>出行前可提前规划工作区域</p>
              <p> 对考察目标进行标记</p>
              <p>标绘支持添加至相关工程表单内用于记录</p>
            </div>

          </div>
        </li>
        <li
          class="li"
          id="lixianditu"
          style="margin-bottom:115px"
        >
          <div class="right">
            <span style="text-align:right;font-style:italic">10</span>
            <h3 style="text-align:right">离线地图</h3>
            <div>
              <p style="text-align:right;width: 100%;">针对外业工作需要</p>
              <p style="text-align:right;width: 100%;">提供常用底图的离线下载及管理</p>
              <p style="text-align:right;width: 100%;"> 辅助通信不畅时地图查看</p>

            </div>

          </div>
          <div class="left">
            <img
              src="../../assets/img/组 2016.png"
              alt=""
            >
          </div>

        </li>
        <li
          class="li"
          id="daochuguanlian"
          style="margin-bottom:152px"
        >
          <div
            class="right"
            style="width:100%"
          >
            <span style="width:100%;textAlign:center;font-style:italic">11</span>
            <h3>导入/导出关联</h3>
            <p style="textAlign:center;lineHeight:30px;width: 100%;">支持 kml、kmz、shp、gpx、dsj 文件的本地导入和直接打开。软件内所记录的数据，支持kmz、csv、照片关联导出及 dsj 压缩文件导出，协助用户一次性获取矢量、数据表及照片相关联的记录信息，并免去外业记录与照片的关联整理，有效提高工作效率。</p>
            <p style="textAlign:center;width: 100%;">（注：dsj为第四记自有文件格式）</p>
          </div>
        </li>
        <li
          class="ul_img"
          style="z-index:-20000;margin-bottom: 0;width: 100%;"
        >
          <img
            src="../../assets/img/蒙版组 59.png"
            alt=""
          >
        </li>
      </ul>
    </div>

    <!-- 底部 -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from "../../components/header/header.vue";
import vFooter from "../../components/footer/footer.vue";
import findid from "../../unitils/navrouter.js";
import { decide_router } from "../../unitils/decide_router";
import axios from "axios";
export default {
  components: { vHeader, vFooter },
  data() {
    return {
      arr: [],
      he: null,
      top: [],
      inner: null,
    };
  },
  methods: {
    // 下载软件
    anddow() {
      axios({
        url: "https://apiv3.geoquater.com/v3/system/update",
        method: "get",
      }).then((res) => {
        if (res.status == 200) {
          console.log(res);
          window.open(res.data.data.apkUrl);
        }
      });
    },
    iosdow() {
      window.open("https://apps.apple.com/cn/app/id1571845119");
    },
    // 滚动事件
    scroll() {
      window.addEventListener("scroll", this.$refs.header.handleScroll, true);
    },
    // 鼠标滑过
    hover() {
      this.$refs.header.mouseover();
    },
    hoverout() {
      this.$refs.header.mouseout(1);
    },
    // getHeight() {
    //   let h = document.getElementById("daochuguanlian").offsetTop;
    //   console.log(h);
    // },
    getHeight() {
      window.addEventListener(
        "scroll",
        () => {
          let data = document.getElementById("data-app");
          if (data) {
            let offheight = data.getBoundingClientRect().top;
            if (offheight < 0) {
              this.$refs.header.navstyle(1);
            } else if (offheight == 0) {
              this.$refs.header.topstyle(1);
            }
          } else {
            return;
          }
        },
        true
      );
    },
    // 二级导航样式
    // secondstyle() {
    //   this.$refs.header.two_style(2);
    // },
  },

  mounted() {
    // window.addEventListener("resize", function () {
    let body = document.body.offsetWidth;
    if (body < 1200) {
      console.log(body);
      self.location = "https://m.geoquater.com/fourthApp";
    }
    // });
    this.scroll();
    this.hover();
    this.hoverout();

    let that = this;
    decide_router(that);
    this.getHeight();

    let li_img = document.querySelectorAll(".yidongapp ul li .left img");
    let li = document.querySelectorAll(".yidongapp ul li");
    // for (let j = 0; j < li_img.length; j++) {
    //   li_img[j].onload = () => {
    //     this.arr.push(li_img[j].clientHeight);
    //     this.top.push(li[j].offsetTop);
    //   };
    // }
    let mmm = document.querySelector(".app_img .img");
    mmm.onload = () => {
      this.he = mmm.clientHeight;
    };
    setTimeout(() => {
      for (let j = 0; j < li_img.length; j++) {
        this.arr.push(li_img[j].clientHeight);
        this.top.push(li[j].offsetTop);
      }
      let eleven = this.top[9] + this.arr[9] + 110;
      this.top.push(eleven);
      findid(this.top, this.he);
    }, 100);
  },
};
</script>
<style scoped>
@import url("../../assets/css/yidongapp/yidongapp.css");
</style>
