var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index"},[_c('v-header',{ref:"header"}),_c('div',{staticClass:"guanyu",staticStyle:{"margin":"0 auto"},attrs:{"id":"data-about"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"join_us"},[_c('div',{staticClass:"jieshao"},[_vm._m(3),_c('div',{staticClass:"join"},[_c('div',{staticClass:"top"},[_vm._v("加入我们")]),_c('div',{staticClass:"middle"},[_vm._v("JOIN US")]),_c('i'),_c('p',[_vm._v("我们衷心地希望有识之士加入到我们的团队，有关招聘的相关内容，敬请关注本页动态~")]),_c('h6',{on:{"click":_vm.lookmore}},[_c('span',{attrs:{"title":"暂无招聘计划"}},[_vm._v("查看更多")]),_vm._m(4)])])])]),_vm._m(5),_c('div',{staticClass:"our"},[_c('h5',{staticClass:"title",staticStyle:{"margin-top":"120px"}},[_vm._v("找到我们")]),_c('p',{staticClass:"text"},[_vm._v("北京市朝阳区北辰西路峻峰华亭D座")]),_c('div',{staticStyle:{"margin-top":"35px"},attrs:{"id":"map"},on:{"click":_vm.map}},[_c('img',{attrs:{"src":require("../../assets/img/地图.png"),"alt":""}})])])]),_c('v-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guanyu_img"},[_c('img',{attrs:{"src":require("../../assets/img/交叉 5.png"),"alt":""}}),_c('h4',[_vm._v("价值/愿景")]),_c('p',{staticClass:"p1"},[_vm._v("记录无止境")]),_c('p',{staticClass:"p2"},[_vm._v("专业价值 服务未来")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"our"},[_c('h5',{staticClass:"title"},[_vm._v("我们能做什么")]),_c('p',{staticClass:"text"},[_vm._v("为企业用户提供数据加工、数据库建设及数据制图等数据服务，为户外工作者提供便捷的数据采集服务，根据客户需求开发工程/科研成果展示平台、数据管理平台、数据共享授权平台等。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"num"},[_c('li',[_c('div',[_c('i',[_vm._v("40")]),_c('span',[_vm._v("+")])]),_c('h6',[_vm._v("合作企业")])]),_c('li',[_c('div',[_c('i',[_vm._v("20")]),_c('span',[_vm._v("+")])]),_c('h6',[_vm._v("覆盖省份")])]),_c('li',[_c('div',[_c('i',[_vm._v("100")]),_c('span',[_vm._v("+")])]),_c('h6',[_vm._v("提供服务")])]),_c('li',[_c('div',[_c('i',[_vm._v("A")]),_c('span',[_vm._v("+")])]),_c('h6',[_vm._v("用户满意度")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('h5',[_vm._v("关于我们")]),_c('p',{staticClass:"eng"},[_vm._v("About us")]),_c('p',{staticClass:"first"},[_vm._v("第四纪数字地球（北京）科技有限公司成立于2020年8月，团队经过近年发展，目前专业技术成员近二十人，地球科学领域合作专家十余位。团队致力于发展先进可靠的数据及计算机技术，为客户提供集数据加工、数据库建设、制图与可视化、平台开发、数据采集于一体的，地球科学数据产出应用解决方案。")]),_c('p',{staticClass:"second"},[_vm._v("公司当前主要业务覆盖全国二十余省份，自研产品“第四记-户外记录app”及“第四记空间平台”已陆续上线运营，旨在为用户提供先进的数据采集管理工具，推动全行业户外工作中数据产出的效率与质量。 ")]),_c('p',{staticClass:"third"},[_vm._v("在信息采集、显示、交互、管理、输出等环节为用户提供多来源、多功能、多场景的位置应用价值。公司秉承“专业价值，服务未来”的企业愿景，以专注的思考、敏锐的视角、高效的协作、积极的行动为这个世界提供专业可靠的价值服务。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/img/1939.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/img/1939.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"our"},[_c('h5',{staticClass:"title",staticStyle:{"margin-top":"120px","margin-bottom":"37px"}},[_vm._v("我们的团队")]),_c('p',{staticClass:"text"},[_vm._v("我们作为新生的专业团队，年轻而充满朝气，具有创新意识并勇于迎接挑战")]),_c('p',{staticClass:"text"},[_vm._v("我们将以专注的思考、敏锐的视角、高效的协作、积极的行动为您提供专业可靠的服务")])])
}]

export { render, staticRenderFns }